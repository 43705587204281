/*
// #Posts
// --------------------------------------------------
*/

.posts {

}
	ul.posts {
		list-style-type: none;
		padding: 0;

		&:not(.post-grid) {
			margin: 0;
		}
	}
	.posts__item {
		margin-bottom: 1em;

		&:last-child {
			margin-bottom: 0;
		}
	}
		// Category Label
		.posts__cat {
			display: block;
			overflow: hidden;
			margin-bottom: 8px;
			padding-right: 5px;
			z-index: 1;
		}
		.posts__cat-label {
			background-color: $color-primary-darken;
			display: block;
			float: left;
			font-style: $post-category-font-style;

			& + .posts__title {
				margin-top: 0.55em;
			}
		}

		.posts__cat-label + .posts__cat-label {
			margin-left: .5em;
		}

			// Label colors and CTA circle links changes depends on category class (.posts__item--XX)
			.posts__item--category-1 {

				.posts__cat-label,
				.posts__cta {
					background-color: $post-category-1;
				}

				.posts__title--color-hover > a:hover {
					color: $post-category-1;
				}

				.posts__thumb--hover {
					&::before {
						background-color: $post-category-1;
					}
				}
			}
			.posts__item--category-2 {

				.posts__cat-label,
				.posts__cta {
					background-color: $post-category-2;
				}

				.posts__title--color-hover > a:hover {
					color: $post-category-2;
				}

				.posts__thumb--hover {
					&::before {
						background-color: $post-category-2;
					}
				}
			}
			.posts__item--category-3 {

				.posts__cat-label,
				.posts__cta {
					background-color: $post-category-3;
				}

				.posts__title--color-hover > a:hover {
					color: $post-category-3;
				}

				.posts__thumb--hover {
					&::before {
						background-color: $post-category-3;
					}
				}
			}
			.posts__item--category-4 {

				.posts__cat-label,
				.posts__cta {
					background-color: $post-category-4;
				}

				.posts__title--color-hover > a:hover {
					color: $post-category-4;
				}

				.posts__thumb--hover {
					&::before {
						background-color: $post-category-4;
					}
				}
			}

			// Specify color for each label separately
			// needed when post has multiple labels
			.posts__item {

				.posts__cat-label--category-1 {
					background-color: $post-category-1;
				}

				.posts__cat-label--category-2 {
					background-color: $post-category-2;
				}

				.posts__cat-label--category-3 {
					background-color: $post-category-3;
				}

				.posts__cat-label--category-4 {
					background-color: $post-category-4;
				}
			}

		// Post Thumb
		.posts__thumb {
			float: left;
			margin: 0 10px 0 0;
		}
		.posts__thumb--hover {
			position: relative;

			&::before {
				content:"";
				display: block;
				position: absolute;
				z-index: 1;
				left: 0;
				right: 0;
				bottom: 0;
				top: 0;
				background-color: $color-primary;
				color: #fff;
				opacity: 0;
				transition: opacity 0.3s ease;
				pointer-events: none;
			}

			> a {
				position: relative;
				display: block;

				&::before,
				&::after {
					content:"";
					display: block;
					position: absolute;
					background-color: #fff;
					overflow: hidden;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%) scale(0);
					z-index: 2;
					transition: all 0.3s ease;
				}

				&::before {
					height: 4px;
					width: 18px;
				}

				&::after {
					height: 18px;
					width: 4px;
				}
			}


			&:hover {

				&::before {
					opacity: 0.8;
				}

				> a {

					&::before,
					&::after {
						transform: translate(-50%, -50%) scale(1);
					}
				}

			}
		}

		// Post Thumb: Video
		.posts__thumb--video {
			position: relative;

			&::before {
				content: "\f04b";
				display: block;
				position: absolute;
				z-index: 1;
				left: 50%;
				top: 50%;
				margin: -30px 0 0 -30px;
				width: 60px;
				height: 60px;
				line-height: 60px;
				text-align: center;
				border-radius: 50% !important;
				color: #fff;
				font-size: 28px;
				text-indent: 4px;
				background-color: rgba(0,0,0,.8);
				text-rendering: auto;
				font-family: 'Font Awesome 5 Free';
				font-weight: 900;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				pointer-events: none;
				transition: transform 0.3s ease;
			}

			&:hover {

				&::before {
					transform: scale(1.1);
				}
			}
		}

		.posts__thumb--video-sm {

			&::before {
				width: 32px;
				height: 32px;
				margin: -16px 0 0 -16px;
				line-height: 32px;
				font-size: 14px;
				text-indent: 2px;
			}
		}

		.posts__thumb--video-md {

			&::before {
				width: 40px;
				height: 40px;
				margin: -20px 0 0 -20px;
				line-height: 40px;
				font-size: 14px;
				text-indent: 3px;
			}
		}

		// Post Thumb: Overlay
		.posts__thumb--video-has-overlay {

			&::after {
				content: "";
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				background-color: rgba(0,0,0,.6);
				pointer-events: none;
			}
		}

		.posts__thumb--video-colored-icon {

			&::before {
				background-color: $color-danger;
			}
		}

		// Post Thumb: Full Height
		.posts__thumb.h-100 {

			img {
				object-fit: cover;
			}
		}

		// Post Thumb: Aspect Ratio
		.posts__thumb--has-ratio {
			float: none;
			margin-right: 0;

			img {
				width: 100%;
				object-fit: cover;
			}
		}

		.posts__thumb--ratio-16-9 {

			img {
				aspect-ratio: 16 / 9;
			}
		}

		.posts__thumb--ratio-16-8-5 {

			img {
				aspect-ratio: 16 / 8.5;
			}
		}

		.posts__thumb--ratio-16-10 {

			img {
				aspect-ratio: 16 / 10;
			}
		}

		.posts__thumb--ratio-20-9 {

			img {
				aspect-ratio: 20 / 9;
			}
		}

		// Post Title
		.posts__title {
			text-transform: $post-title-text-transform;
			letter-spacing: -0.03em;
			font-style: $post-title-font-style;

			& + .posts__date {
				margin-top: 0.7em;
			}

			a {
				color: $post-title-color;
				transition: color 0.2s ease-in-out;

				&:hover {
					color: $post-title-color-hover;
				}
			}
		}

		// Post date
		.posts__date {
			display: inline-block;
			text-transform: uppercase;
			font-family: $font-family-accent;
			line-height: 1.2em;
		}


	/* Simple Posts List */
	.posts--simple-list {

		.posts__item {
			overflow: hidden;
			margin-bottom: 18px;

			.card & {
				border-bottom: 1px solid $card-border-color;
				margin-left: -$card-horizontal-padding;
				margin-right: -$card-horizontal-padding;
				padding-left: $card-horizontal-padding;
				padding-right: $card-horizontal-padding;
				padding-bottom: $card-vertical-padding;
				margin-bottom: $card-vertical-padding;
			}

			&:last-child,
			.card &:last-child {
				border-bottom: none;
				padding-bottom: 0;
				margin-bottom: 0;
			}
		}

		// Numbered
		.card &.posts--simple-list-numbered .posts__item {
			padding-left: 100px;

			&::before {
				width: 100px;
				text-align: center;
				margin-top: -16px;
			}

			&:last-child {

				&::before {
					margin-top: -4px;
				}
			}

			.posts__title {
				font-size: 14px;
			}
		}

			.posts__inner {
				overflow: hidden;

				.widget--sidebar & {

					@if $template == 'esports' {
						padding-top: 4px;
					}
				}
			}

			// Post Category label
			.posts__cat-label {

				& + .posts__title {
					margin-top: 0.55em;
				}
			}

			// Post Title
			.posts__title {
				margin-bottom: 0;
				line-height: 1em;

				@if $template == 'football' {
					font-size: 14px;
				}

				@if $template == 'esports' {
					font-size: 14px;
					line-height: 1.2em;
				}

				// Post Title in the Footer Widgets Area
				.widget--footer & {
					font-size: 12px;
					line-height: $footer-widget-post-title-line-height;
					font-style: $footer-widget-post-title-font-style;

					> a {
						transition: color 0.2s ease;

						&:not(:hover) {
							color: $color-white;
						}
					}

					&:not(.posts__title--color-hover) {

						> a {

							&:hover {
								color: $link-color-hover;
							}
						}
					}

					& + .posts__date {
						margin-top: 0.25em;
					}
				}

				// Post Title smaller in the Megamenu
				.main-nav__megamenu & {

					@if $template == 'football' or $template == 'esports' {
						font-size: 14px;

						@media (max-width: 991px) {
							font-size: 12px;

							> a {
								color: #fff;
							}
						}
					} @else {
						font-size: 12px;

						@media (max-width: 991px) {
							font-size: 11px;

							> a {
								color: #fff;
							}
						}
					}

					@media (min-width: 992px) {

						> a:not(:hover) {
							color: $nav-sub-megamenu-post-title-color;
						}
					}
				}
			}

			// Post Thumb
			.posts__thumb {
				border-radius: $footer-widget-post-thumb-border-radius;

				.main-nav__megamenu & {


				}

				&::before {
					border-radius: $footer-widget-post-thumb-border-radius;
				}

				img {
					border-radius: $footer-widget-post-thumb-border-radius;
				}
			}

			// Post Date
			.posts__date,
			.posts__comments {
				font-size: 10px;
				line-height: 1.2em;
				vertical-align: middle;
				display: inline-block;

				.main-nav__megamenu & {

					@media (max-width: 991px) {
						font-size: 8px;
						@include fix_small_chars();
					}
				}

				// Post Date in the Footer Widgets Area
				.widget--footer & {
					font-size: 9px;

					@if $template == football {
						font-size: 10px;
					}
				}
			}

			// Post Comments
			.posts__comments {

				i {
					font-size: 12px;
					margin-right: 3px;
					margin-left: 1px;
					display: inline-block;
					vertical-align: middle;
				}

				.widget--footer & {
					min-width: 57px;
				}
			}

			// Post Excerpt
			.posts__excerpt {
				font-size: 14px;
				line-height: 22px;
				margin-top: 6px;

				@if $template == 'football' {
					font-size: 13px;
					margin-top: 10px;
				}

				@if $template == 'esports' {
					font-size: $font-size-sm;
					line-height: 1.8333em;
					margin-top: 14px;
				}

				.main-nav__megamenu & {

					@media (max-width: 991px) {
						font-size: 11px;
						line-height: 17px;
					}
				}
			}
				.posts__excerpt--space {
					padding: 20px 0;
				}
				.posts__excerpt--space-sm {
					padding-top: 10px;
				}

			// Post Footer
			.posts__footer {
				padding-top: 17px;

				.card & {
					padding: 17px $card-horizontal-padding;
					border-top: 1px solid $card-border-color;
					overflow: hidden;
					margin-left: -$card-horizontal-padding;
					margin-right: -$card-horizontal-padding;
				}
			}

				.post-author {
					float: left;
					width: 50%;

					@media (max-width: 479px) {
						width: 40%;
					}
				}
					.post-author__avatar {
						width: 24px;
						height: 24px;
						margin-right: 4px;
						vertical-align: middle;
						border-radius: 50%;
						overflow: hidden;

						img {
							vertical-align: top;
						}

						@media (max-width: 479px) {
							display: none;
						}
					}
					.post-author__name {
						font-size: 10px;
						text-transform: uppercase;
						color: $body-font-color;
						font-weight: 400;
					}

				.post__meta {
					width: 50%;
					float: right;
					text-align: right;

					@media (max-width: 479px) {
						width: 60%;
					}
				}

			// Post More
			.posts__more {
				padding: 20px 0 0 0;
			}
	}

	/* Simple Posts List - Condensed */
	.posts--simple-list-condensed {

		.posts__item {

			.card & {
				padding-bottom: 16px;
				margin-bottom: 18px;
			}
		}
	}

	/* Simple Post List - Numbered */
	.posts--simple-list-numbered {
		counter-reset: list;

		.posts__item {
			padding-left: 100px;
			position: relative;
			counter-increment: list;

			.widget--footer & {
				padding-left: 38px;
			}

			&::before {
				content: counter(list, decimal-leading-zero);
				font-family: $font-family-accent;
				font-size: 44px;
				line-height: 1em;
				font-style: italic;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				opacity: .2;

				.widget--footer & {
					font-size: 24px;
				}
			}
		}
	}

	/* Simple Posts List - Large */
	.posts--simple-list--lg {

		.posts__item {

			.widget--side-panel & {
				margin-bottom: 50px;

				&:last-child {
					margin-bottom: 0;
				}
			}

			.main-nav__megamenu & {
				padding-top: 20px;
				padding-bottom: 20px;

				@media (min-width: 992px) {
					padding-left: 10px;
					padding-right: 10px;
					padding-top: 0;
					padding-bottom: 0;
				}

				@media (min-width: 1200px) {
					padding-left: 15px;
					padding-right: 15px;
				}

			}

			.posts__title {

				// Post Title in the Megamenu
				.main-nav__megamenu & {

					@media (min-width: 992px) {
						font-size: 16px;
					}

					@media (min-width: 1200px) {
						font-size: 22px;
					}
				}
			}
		}

		.posts__cat {
			margin-bottom: 14px;
		}

			.posts__cat-label {

				@media (min-width: 992px) {
					font-size: 10px;
				}
			}

		.posts__title {
			margin-bottom: .2em;

			@media (min-width: 992px) {
				font-size: 18px;
				line-height: 1em;

				.widget--side-panel & {
					font-size: 22px;
				}
			}
		}

		.posts__excerpt {
			font-size: $base-font-size;
			line-height: $base-line-height;
		}
	}

	/* Simple Posts List - XLarge */
	.posts--simple-list--xlg {

		.posts__cat {
			margin-bottom: 14px;
		}

			.posts__cat-label {

				@media (min-width: 992px) {
					font-size: 10px;
				}
			}

		.posts__title {
			margin-bottom: .1em;

			@media (min-width: 768px) {
				font-size: 30px;
			}

			@media (min-width: 992px) {
				font-size: 24px;
			}

			@media (min-width: 1199px) {
				font-size: 30px;
				line-height: 28px;
			}
		}

		.posts__date {
			font-size: 9px;

			@media (min-width: 992px) {
				font-size: 11px;
			}
		}

		.posts__excerpt {
			font-size: $base-font-size;
			line-height: $base-line-height;

			@media (min-width: 1199px) {
				margin-top: 13px;
			}
		}

		.posts__more {

			@media (min-width: 992px) {
				padding-top: 30px;
			}

			@media (min-width: 1199px) {
				padding-top: 38px;
			}
		}
	}


	/* Simple Posts List - Search Result */
	.posts--simple-list--search {

		.posts__item {

		}
	}


	/* Posts List - Horizontal */
	.posts-layout-horizontal {
		display: flex;

		@media (max-width: 991px) {
			flex-wrap: wrap;
		}

		@media (min-width: 992px) {

			.main-nav__megamenu & {
				padding-bottom: 22px;
				margin-bottom: 30px !important;
				margin-left: -$nav-sub-megamenu-padding-horizontal !important;
				margin-right: -$nav-sub-megamenu-padding-horizontal !important;
				padding-left: $nav-sub-megamenu-padding-horizontal - 8px;
				padding-right: $nav-sub-megamenu-padding-horizontal - 8px;
				border-bottom: 1px solid $nav-sub-border-color;
			}
		}

		.posts__item {
			padding-right: 8px;
			padding-left: 8px;

			// Make thumbs smaller on mobile devices
			@media (max-width: 991px) {
				padding-left: 0;
				padding-right: 0;

				.main-nav__megamenu & {

					.posts__thumb {
						max-width: 35%;
					}
				}
			}

			@media (min-width: 992px) {
				padding-right: 20px;
			}

			@media (min-width: 1199px) {
				padding-right: 30px;
			}
		}
	}

	.posts-layout-horizontal--3cols {

		.posts__item {
			flex-basis: 100%;

			@media (min-width: 992px) {
				flex-basis: 33.3%;
			}
		}
	}


/* Posts List - Skewed */
.posts--list-skewed {

	.card & {
		margin-block: -$card-vertical-padding !important;
	}

	&.list-group {
		border-radius: 0;
	}

	.posts__item {
		padding: 0;
		margin-bottom: 0;
		display: grid;
		grid-template-rows: auto;
		grid-template-columns: 109px 1fr;
		margin-block-end: $card-margin-vertical;

		.card & {
			margin-inline: -$card-horizontal-padding;
			border-bottom: 1px solid $card-border-color;
			margin-block-end: 0;
		}

		&:last-child {
			border-bottom: none;
		}

		&.list-group-item {
			background-color: $card-subheader-bg;
			color: $body-font-color;
			grid-template-columns: $card-horizontal-padding 1fr;
			border-left: none;
			border-right: none;
			border-top: none;
			padding-right: calc(109px - #{$card-horizontal-padding});

			.posts__thumb {
				opacity: 0;
				transition: opacity .3s ease;
			}

			&.active {
				background-color: $card-bg;
				margin-top: 0;
				grid-template-columns: 109px 1fr;
				padding-right: 0;
				border-left: none;
				border-right: none;
				border-top: none;

				.posts__thumb {
					opacity: 1;
				}
			}
		}
	}

	// with Meta
	.posts__item--with-meta {
		grid-template-rows: 1fr auto;
		grid-template-columns: 109px 1fr;

		.posts__thumb {
			grid-area: 1 / 1 / 2 / 2;
		}

		.posts__inner {
			grid-area: 1 / 2 / 3 / -1;
		}

		.posts__footer {
			grid-area: -2 / 1 / -1 / -1;

			.post__meta {

				.meta__item {
					padding-block: 1.25em;
				}
			}
		}

		&.posts__item--no-thumb {
			.posts__inner {
				grid-area: 1 / 1 / 2 / -1;
			}

			.posts__footer {
				grid-area: 2 / 1 / -1 / -1;

			}
		}
	}

	// Overlay
	.posts__item-overlay {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr auto;
		isolation: isolate;

		.posts__thumb {
			grid-area: 1 / 1 / 2 / -1;
			z-index: -1;

			img {
				clip-path: none;
			}
		}
		.posts__inner {
			grid-area: 1 / 1 / 2 / -1;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			z-index: 1;
			padding-inline-start: $card-horizontal-padding;
			background-color: rgba(0,0,0,.6);

			.post__meta {
				color: $color-gray;
			}
		}
			.posts__title {
				line-height: 1.18em;
				color: $color-white;
				margin-block-end: 0;

				@media (min-width: 992px) {
					font-size: $font-size-h3;
				}

				a {
					color: $color-white;

					&:hover {
						color: $color-white;
						opacity: .8;
					}
				}
			}
				.posts__cat-label {
					font-size: 10px;
					padding-block: .7em .6em;
				}
		.posts__footer {
			grid-area: 2 / 1 / -1 / -1;
		}
	}

	// Large
	.posts__item--lg {

		.posts__title {
			@media (min-width: 992px) {
				font-size: $font-size-h3;
				line-height: 1.18em;
			}
		}
		.posts__excerpt {
			font-size: $base-font-size;
			line-height: 1.86em;
		}
		.posts__cat-label {
			font-size: 10px;
			padding-block: .7em .6em;
		}
		.posts__footer {
			.post__meta {
				.meta__item {
					padding-block: 1.85em;
				}
			}
		}
	}
		.posts__thumb {
			margin: 0;
			position: relative;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				clip-path: polygon(0 0, 100% 0, 54.5% 100%, 0% 100%);
			}

			.meta-video-duration {
				display: block;
				position: absolute;
				inset: auto auto 0 0;
				background-color: rgba(0,0,0,.6);
				font-size: 10px;
				line-height: 1.2em;
				padding: .3em .6em;
				color: $color-white;
				font-weight: bold;
				font-family: $font-family-accent;
			}
		}

		.posts__inner {
			padding-block: $card-vertical-padding * 0.75;
			padding-inline-end: $card-horizontal-padding;
		}

		.posts__title {
			font-size: $base-font-size;
			line-height: 1.143em;
			margin-bottom: .75em;
		}

		.posts__excerpt {
			font-size: $font-size-sm;
			line-height: 1.67em;
		}

	// No thumb
	.posts__item--no-thumb {
		grid-template-columns: 1fr;

		@media (min-width: 992px) {
			grid-template-columns: 1fr;
		}

		.posts__inner {
			padding-inline-start: $card-horizontal-padding;
		}
	}
}

.posts--list-skewed-with-meta {

}

.posts--list-skewed-lg {

	.posts__item {

		@media (min-width: 992px) {
			grid-template-columns: 133px 1fr;
		}

		.post__meta--row {

			&.post__meta--sm {
				margin-block-end: 15px;
			}
		}
	}

	// No thumb
	.posts__item--no-thumb {
		grid-template-columns: 1fr;

		@media (min-width: 992px) {
			grid-template-columns: 1fr;
		}
	}
}



/* Post: Cards */
.posts--cards {

}
	.posts__item--card-row {
		flex-direction: row;
	}
	.posts__item--card {
		height: calc(100% - #{$card-margin-vertical});

		&,
		&:last-child {
			margin-bottom: $card-margin-vertical;
		}

		.posts__thumb {
			float: none;
			margin: 0;
			backface-visibility: hidden;
			transform: translate3d(0, 0, 0);

			&:first-child {
				overflow: hidden;
				border-radius: $card-border-radius $card-border-radius 0 0;
			}

			&:last-child {
				overflow: hidden;
				border-radius: 0 0 $card-border-radius $card-border-radius;
			}

			img {
				width: 100%;
				height: auto;
				transition: transform 0.5s ease;
				backface-visibility: hidden;
			}

			&:hover {

				img {
					transform: scale(1.1);
				}
			}

			.posts__cta {
				top: 13px;
				right: 13px;
				transform: none;
			}
		}

		&.posts__item--card-condensed {
			margin-bottom: 0;
			border-bottom: none;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}

		.posts__cta {
			display: block;
			position: absolute;
			top: 0;
			right: 23px;
			width: 50px;
			height: 50px;
			overflow: hidden;
			border-radius: 50%;
			transform: translateY(-50%);

			&::before,
			&::after {
				content:"";
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				background-color: #fff;
				transition: transform 0.25s ease;
			}

			&::before {
				width: 17px;
				height: 3px;
			}

			&::after {
				width: 3px;
				height: 17px;
			}

			&:hover {

				&::before,
				&::after {
					transform: translate(-50%, -50%) rotate(90deg);
				}
			}
		}

		.posts__cat {
			display: block;
			position: absolute;
			left: 23px;
			top: 23px;
		}
		.posts__inner .posts__cat {
			position: static;
			margin-bottom: 18px;
		}
			.posts__cat-label {
				font-size: 10px;
				padding-top: .6em;
			}

		.posts__date {
			font-size: 10px;
			line-height: 1.2em;

			@if $template == 'esports' {
				font-style: italic;
				font-size: 12px;
				font-weight: 700;
			}

			& + .posts__title {
				margin-top: 5px;

				@media (min-width: 992px) {
					margin-top: 10px;
				}
			}

			@media (min-width: 992px) {
				font-size: 11px;
			}
		}
		.posts__title {
			font-size: $post-title-font-size-sm;
			line-height: $post-title-line-height;
			margin-bottom: 0.2em;

			@media (min-width: 992px) {
				font-size: $post-title-font-size;

				@if $template == 'esports' {
					margin-bottom: 0;
				}
			}

			@media (min-width: 1200px) {
				font-size: $post-title-font-size-lg;
			}
		}
			.posts__title--sm {

				@media (min-width: 992px) {
					font-size: 18px;
				}

				+ .posts__excerpt {

					@media (min-width: 992px) {
						padding: 10px 0 0 0;
					}
				}
			}

		.posts__excerpt {
			padding: $post-excerpt-padding-top-sm 0 0 0;
			font-size: $post-excerpt-font-size;
			line-height: $post-excerpt-line-height;

			@media (min-width: 992px) {
				padding: $post-excerpt-padding-top 0 8px 0;
			}
		}

		.posts__inner {
			padding-top: 18px;
			position: relative;
			flex-grow: 1;
		}

		// Post Footer
		.posts__footer {
			padding: 17px $card-horizontal-padding;
			border-top: 1px solid $card-border-color;
			display: flex;
			width: 100%;
		}

			.post-author {
				flex-basis: 50%;
				text-align: left;

				@media (max-width: 479px) {
					flex-basis: 40%;
				}
			}
				.post-author__avatar {
					width: 24px;
					height: 24px;
					margin-right: 4px;
					vertical-align: middle;
					border-radius: 50%;
					overflow: hidden;

					img {
						vertical-align: top;
					}

					@media (max-width: 479px) {
						display: none;
					}
				}
				.post-author__name {
					font-size: 10px;
					text-transform: uppercase;
					color: $body-font-color;
					font-weight: 400;
					font-style: normal;
				}

			// Post Meta
			.post__meta {
				flex-basis: 50%;
				margin-left: auto;
				text-align: right;
			}
	}
	.posts__item--card-height-auto {
		height: auto;
	}


/* Posts: Cards Compact (video) */
.posts--card-compact {

	.posts__item {

		&,
		&:last-child {
			margin-bottom: 15px;
		}

		.posts__thumb {
			float: none;
			margin: 0;
			backface-visibility: hidden;
			transform: translate3d(0, 0, 0);

			&:first-child {
				overflow: hidden;
				border-radius: $card-border-radius $card-border-radius 0 0;
			}

			&:last-child {
				overflow: hidden;
				border-radius: 0 0 $card-border-radius $card-border-radius;
			}

			img {
				width: 100%;
				height: auto;
				transition: transform 0.5s ease;
				backface-visibility: hidden;
			}

			&:hover {

				img {
					// transform: scale(1.1);
				}
			}

		}

		.posts__cat {
			margin-bottom: 12px;
		}

		.posts__title {
			font-size: 18px;
			line-height: 1.11em;
			margin-bottom: 0.4em;
		}
	}

	// Large Item
	.posts__item--lg {

		.posts__cat {

		}
			.posts__cat-label {
				font-size: 10px;
			}

		.posts__title {

			@media (min-width: 992px) {
				font-size: 26px;
				line-height: 1em;
			}
		}
	}
}


/* Post: Tile */
.posts--tile {

	.main-nav__megamenu & {
		margin: -44px -65px -44px 0;

		@media only screen and (max-width: 991px) {
			margin: 0;

			.posts__footer,
			.posts__cta {
				display: none;
			}
		}

		.posts__item--tile {
			margin-bottom: 0 !important;
			border-radius: 0;
		}

		.posts__thumb {

			img {
				border-radius: 0;
			}
		}
	}
}
	.posts__item--tile {
		position: relative;
		margin-bottom: 15px !important;

		.posts__thumb {
			margin: 0;
			float: none;
			position: relative;

			img {
				border-radius: $card-border-radius;
				width: 100%;
				height: auto;
			}

			&::after {
				content: "";
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				top: 0;
				z-index: 1;
			}
		}

		.posts__inner {
			position: absolute;
			text-align: center;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 4;
		}

		.posts__inner--centered {
			top: 50%;
			transform: translateY(-50%);
		}

			.posts__cat {

				.posts__cat-label {
					float: none;
					display: inline-block;
					font-size: 10px;

				}
			}

			.posts__title {
				padding-left: $card-horizontal-padding;
				padding-right: $card-horizontal-padding;
				margin-bottom: .5em;
				line-height: 1em;

				@media (min-width: 992px) {
					font-size: 24px;
					line-height: 1.0833em;
				}
			}

			.posts__cta {
				display: block;
				position: absolute;
				right: $card-vertical-padding;
				top: $card-horizontal-padding;
				width: 50px;
				height: 50px;
				overflow: hidden;
				// background-color: $color-primary-darken;
				border-radius: 50%;
				z-index: 4;

				&::before,
				&::after {
					content:"";
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					background-color: #fff;
					transition: transform 0.25s ease;
				}

				&::before {
					width: 17px;
					height: 3px;
				}

				&::after {
					width: 3px;
					height: 17px;
				}

				&:hover {

					&::before,
					&::after {
						transform: translate(-50%, -50%) rotate(90deg);
					}
				}
			}

			.posts__date {
				font-size: 11px;
				color: #fff;
				margin-bottom: 2.5em;
			}

			.posts__footer {
				border-top: 1px solid $card-border-color;
				padding-top: 16px;
				padding-bottom: 16px;
				text-align: center;
			}
				.post-author {
					display: inline-block;
					margin-right: 14px;
					position: relative;
					top: -2px;
				}
					.post-author__avatar {
						display: inline-block;
						vertical-align: middle;
						border-radius: 50%;
						overflow: hidden;
						width: 24px;
						height: 24px;
						float: none;
						margin-right: 5px;
					}
					.post-author__info {

					}
						.post-author__name {
							margin: 0;
							display: inline-block;
							font-size: 10px;
							font-weight: 400;
							font-style: normal;
							color: $color-gray;
						}

				.post__meta {
					display: inline-block;
				}
	}

// Post Tile Alt
.posts--tile-alt {

	.posts__item-link-overlay {
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background-color: transparent;
		opacity: .8;
		z-index: 4;
		border-top-left-radius: $card-border-radius;
		border-top-right-radius: $card-border-radius;
		transition: background-color .3s ease;

		&:hover {
			background-color: $post-card-overlay-color;

			+ .posts__inner--noactive .posts__excerpt {
				max-height: 70px;
				opacity: 1;
			}
		}
	}

	// .posts__item-link-overlay:hover

	.posts__thumb {

		img {
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
		}

		&::after {
			content: "";
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			top: 20%;
			opacity: .6;
			background-image: linear-gradient(to bottom, transparent 0%, #000 100%);
		}
	}

	.posts__inner {
		text-align: left;
		padding: calc($card-vertical-padding / 2) $card-horizontal-padding;
	}
	.posts__inner--noactive {
		pointer-events: none;
	}
		.posts__cat {
			margin-bottom: 10px;
		}
		.posts__title {
			padding-left: 0;
			padding-right: 0;
			line-height: 1em;
			margin-bottom: .15em;
			font-size: 20px;

			@media (min-width: 992px) {
				font-size: 24px;
			}

			@media (min-width: 1200px) {
				font-size: 30px;
			}
		}
		.posts__date {
			margin-bottom: 0;
			font-weight: bold;

			@if $template == 'esports' {
				font-style: italic;
			}
		}
		.posts__excerpt {
			max-height: 0;
			opacity: 0;
			overflow: hidden;
			padding-top: 10px;
			padding-bottom: 10px;
			transition: max-height .4s ease, opacity .7s ease;
		}
	.posts__footer {
		text-align: left;
		display: flex;
		padding-left: $card-horizontal-padding;
		padding-right: $card-horizontal-padding;

		@media (max-width: 991px) {
			flex-wrap: wrap;
		}
	}
		.post-author {

		}
		.post__meta {
			margin-left: auto;
		}
}

// Post Tile (small)
.posts__item--tile-sm {

	.posts__title {

		@media (min-width: 992px) {

			@if $template == 'esports' {
				font-size: 26px;
			} @else {
				font-size: 22px;
			}
		}
	}
}

// Post Tile (no paddings)
.posts--tile-alt-nopaddings {
	margin-left: 0;
	margin-right: 0;

	.post-grid__item {
		padding-left: 0;
		padding-right: 0;
	}
		.posts__item {
			margin-bottom: 0 !important;
		}

		.posts__thumb {

			img {
				border-top-right-radius: 0;
				border-top-left-radius: 0;
			}
		}
}

// Post Tile - Meta info alternative position
.posts--tile-meta-position {

	.posts__title + .posts__date {
		margin-top: 0;
		margin-right: 10px;
	}

	.meta__item + .meta__item {

		@media (min-width: 992px) {
			margin-left: 8px;
		}
	}
}

// Post Tile - Thumb bordered
.posts--tile-thumb-bordered {

	.posts__thumb {
		border-bottom-right-radius: $card-border-radius;
		border-bottom-left-radius: $card-border-radius;
		overflow: hidden;

		img {

		}
	}
		.posts__inner {

		}
}

// Post Tile - Hero Posts Hrid
.posts--tile-hero-post-grid {
	padding-top: 20px;

	@media (min-width: 992px) {
		padding-top: 30px;
	}

	@media (min-width: 1200px) {
		padding-top: 40px;
	}

	.posts__title {

		@media (min-width: 992px) {
			font-size: 20px;
		}

		@media (min-width: 1200px) {
			font-size: 22px;
		}
	}

	.posts__title--lg {

		@media (min-width: 992px) {
			font-size: 36px;
		}
	}

	.posts__item-link-overlay {
		border-radius: 0;
	}
}

// Post Tile (large)
.posts__item--tile-lg {

	.posts__title {

		@media (min-width: 992px) {
			font-size: 34px;
		}
	}
}

// Posts Related
.posts--tile-alt-noborder {

	.posts__item--tile {
		border: 0;
		border-radius: 0;
		margin-bottom: 0 !important;
	}

	.posts__thumb {
		border-radius: 0;

		img {
			border-radius: 0;
		}
	}

	.posts__title {

		@media (min-width: 1200px) {
			font-size: 36px;
		}
	}

	.posts__inner {

		@media (min-width: 1200px) {
			padding: 40px;
		}
	}
}

/* Post Tile - Event */
.posts__item--tile-event {

	.posts__inner {
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		top: 0;
		padding-top: 30px;
		padding-bottom: 20px;
		font-size: $font-size-sm;
		line-height: 1.833em;
		color: $headings-color;

		@media (min-width: 992px) {
			padding-top: 45px;
			padding-bottom: 30px;
		}

		@media (min-width: 1200px) {
			padding-top: 60px;
			padding-bottom: 40px;
		}
	}
		.posts__thumb {

			&::after {
				top: 0;
				background-image: linear-gradient( 120deg, $color-2 0%, $color-3 100%);
				opacity: .4;
				z-index: 3;
			}
		}
		.posts__excerpt {
			padding: 10px 0;

			@media (min-width: 992px) {
				padding: 10px 20px;
			}
		}

		.posts__countdown {
			margin-top: auto;
			margin-bottom: auto;
		}

		.posts__logo {

			img {
				width: auto;
				height: auto;
				margin: 0 auto;
			}
		}

		.countdown-counter__label {
			color: $headings-color;
		}
}


/* Post Grid */
.post-grid {

	.post-grid__item {

	}
}
	.post-grid--2cols {

		.post-grid__item {

			@media (max-width: 479px) {
				width: 100%;
			}
		}
	}


.post-grid--featured {
	padding-top: 15px;
}


/* Post: Cards Thumb Left */
.posts--cards-thumb-left {

	.posts__item--card {
		overflow: hidden;
	}
		.posts__thumb {
			width: 100%;
			float: none;
			overflow: hidden;
			height: 200px;

			@media (min-width: 375px) {
				height: 240px;
			}

			@media (min-width: 768px) {
				float: left;
				margin: 0;
				max-width: 325px;
				height: auto;

				&:first-child {
					border-radius: $card-border-radius 0 0 $card-border-radius;
				}
			}

			@media (min-width: 992px) {
				max-width: 220px;
			}

			@media (min-width: 1199px) {
				max-width: 380px;
			}
		}

		.posts__inner {
			overflow: hidden;
			padding-top: 0;

			@media (min-width: 992px) {
				padding-top: 8px;
			}
		}

			.posts__cta {
				position: absolute;
				left: 20px;
				top: 20px;
				transform: none;

				&:hover {
					transform: rotate(90deg);
				}
			}

}


/* Post: Cards Thumb Large */
.posts--cards-thumb-lg {

	.posts__inner {

		@media (min-width: 992px) {
			padding-top: 30px;
		}
	}

	.posts__cat {
		position: static;

		@media (min-width: 992px) {
			margin-bottom: 17px;
		}
	}

	.posts__title {

		@media (min-width: 992px) {
			font-size: 28px;
			line-height: 1.07em;
		}
	}

}

/* Post: Blockquote */
.posts__item--quote {
	background-color: $post-quote-bg;
	border-radius: $card-border-radius;
	position: relative;
	overflow: hidden;
	min-width: 304px;

	@media (min-width: 992px) {
		min-height: 482px;
	}
	@media (min-width: 1199px) {
		min-height: 488px;
	}

	&::before {
		content:"\201C";
		display: block;
		position: absolute;
		left: -0.09em;
		top: -0.18em;
		font-family: $font-family-accent;
		color: #fff;
		opacity: .1;
		font-size: 300px;
		line-height: 1em;
		pointer-events: none;

		@media (min-width: 992px) {
			font-size: 600px;
		}
		@media (min-width: 1199px) {
			font-size: 700px;
		}
	}
}
.blockquote {

}
	.blockquote--card {
		border: none;
		padding: 0;
		margin: 0;
		width: 100%;

		.blockquote__content {
			@include font-accent(16px);
			line-height: 1em;
			color: #fff;
			font-style: $post-quote-font-style;
			margin: 0;
			padding-bottom: 50px;
			position: relative;
			z-index: 1;

			@media (min-width: 992px) {
				font-size: 24px;
			}

			@media (min-width: 1199px) {
				font-size: 36px;
			}
		}
			.blockquote-stress {
				display: block;
				color: $post-quote-highlight;
			}

		.blockquote__footer {
			@include font-accent(12px);
			color: #fff;
			line-height: 1.2em;
			position: absolute;
			right: $card-horizontal-padding;
			bottom: $card-vertical-padding;

			&::before {
				display: none;
			}

			@media (min-width: 992px) {
				text-align: right;
			}
		}
			.blockquote__cite {
				font-style: $post-quote-footer-font-style;
			}
			.blockquote__author-name {
				display: block;
				font-size: 11px;

				@media (min-width: 992px) {
					font-size: 14px;
				}
			}
			.blockquote__author-info {
				font-size: 9px;
				color: rgba(255,255,255,.7);
				display: block;

				@media (min-width: 992px) {
					font-size: 10px;
				}
			}
	}


/* Posts: Featured Slider */
.posts--slider-featured {

	&:hover {

		.posts__thumb img {
			transform: scale(1.1);
		}
	}

	.posts__item {
		border-radius: 4px;
		overflow: hidden;
		position: relative;
		margin-bottom: 0;
		backface-visibility: hidden;
		transform: translate3d(0,0,0);
	}

		.posts__thumb {
			margin: 0;

			img {
				border-radius: 4px;
				position: relative;
				transition: transform 0.3s ease-in-out;
				backface-visibility: hidden;
			}
		}

		.posts__inner {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			padding: 20px;
			background-image: linear-gradient(to bottom, transparent, #000);
			border-radius: 0 0 4px 4px;
			transform: translate3d(0,0,0);

			@media (min-width: 992px) {
				padding: 30px 23% 30px 40px;
			}
		}
			.posts__cat {

				@media (min-width: 992px) {
					margin-bottom: 13px;
				}
			}
				.posts__cat-label {

					@media (min-width: 992px) {
						font-size: 10px;
					}
				}

			.posts__title {
				font-size: 14px;
				line-height: 1em;
				margin-bottom: 0.4em;
				color: #fff;

				@media (min-width: 768px) {
					font-size: 24px;
				}

				@media (min-width: 992px) {
					font-size: 32px;
				}

				@media (min-width: 1199px) {
					font-size: 38px;
					line-height: 34px;
				}

				.text-primary {
					display: block;
				}
			}
				.posts__title-higlight {

					@if $template == basketball {
						color: $color-primary;
					} @else {
						color: $color-4-darken;
					}
				}

		.post-author {
			color: #fff;
		}
			.post-author__avatar {
				width: 24px;
				height: 24px;
				margin-right: 4px;

				@media (min-width: 768px) {
					margin-right: 8px;
				}
			}
			.post-author__info {

			}
				.post-author__name,
				.posts__date {
					font-size: 9px;
					color: #fff;
					text-transform: uppercase;
					display: inline-block;
					font-weight: 700;
					margin-bottom: 0;

					@media (min-width: 768px) {
						font-size: 11px;
					}
				}
			.posts__date {

				&::before {
					content: "\2013";
					margin-right: 0.25em;
				}
			}
}

/* Featured Slider - Center */
.posts--slider {

}
	.posts-slider--center {

		.posts__thumb {
			float: none;
			position: relative;

			&::before {
				content: "";
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				background-color: #000;
				opacity: 0.6;
				z-index: 1;
			}
		}

		.posts__inner {
			top: 50%;
			left: 50%;
			transform: translate3d(-50%,-50%,0); // needed translate3d to fix jumping issue in Chrome
			right: auto;
			background: none;
			text-align: center;
			width: 80%;
			padding: 0;
			z-index: 2;

			@media (min-width: 992px) {
				padding: 0;
				width: 60%;
			}
		}

		.posts__cat {

		}
			.posts__cat-label {
				float: none;
				display: inline-block;

			}

		.posts__title {
			font-size: 14px;

			@media (min-width: 768px) {
				font-size: 20px;
			}

			@media (min-width: 992px) {
				font-size: 24px;
			}

			@media (min-width: 1199px) {
				font-size: 32px;
				line-height: 32px;
			}
		}
	}


/* Featured Carousel */
.posts--carousel-featured {

	.posts__item {
		position: relative;
	}

		.posts__title {
			color: #fff;
			margin-bottom: .25em;
			font-size: 15px;

			@media (min-width: 480px) {
				font-size: 13px;
			}

			@media (min-width: 768px) {
				font-size: 13px;
			}

			@media (min-width: 992px) {
				font-size: 15px;
			}

			@media (min-width: 1199px) {
				font-size: 18px;
				line-height: 1.11em;
			}
		}

		.posts__cat {

			@media (min-width: 992px) {
				margin-bottom: 10px;
			}

			@media (min-width: 1199px) {
				margin-bottom: 17px;
			}
		}
			.posts__cat-label {
				float: none;
				display: inline-block;

				@media (min-width: 992px) {
					font-size: 10px;
				}
			}

		.posts__thumb {
			float: none;
			position: relative;
			margin: 0;

			img {
				width: 100%;
				vertical-align: top;
			}
		}

		.posts__inner {
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			padding: 0 15%;
			text-align: center;

			@media (max-width: 768px) and (min-width: 992px) {
				padding-left: 10px;
				padding-right: 10px;
			}
		}

		.posts__date {
			color: $body-font-color;
			font-size: 9px;
			font-weight: 700;

			@media (min-width: 992px) {
				font-size: 11px;
			}
		}

		.post__meta {
			padding-top: 5px;

			@media (min-width: 992px) {
				padding-top: 10px;
			}

			@media (min-width: 1199px) {
				padding-top: 22px;
			}
		}
			.meta__item {
				color: #fff;
			}
}

/* Posts - Horizontal */
.posts--horizontal {
	display: flex;

	@media (max-width: 767px) {
		flex-direction: column;
	}

	.posts__item,
	.card & .posts__item {

		@media (min-width: 768px) {
			padding-bottom: 0;
			border-left: 1px solid $card-border-color;
			border-bottom: none;
			margin: -$card-vertical-padding 0 !important;

			&:first-child  {
				border-left: none;
				padding-left: 0;

				.posts__inner {
					padding-left: 0;
				}
			}

			&:last-child {
				padding-right: 0;

				.posts__inner {
					padding-right: 0;
				}
			}

			.posts__inner {
				padding: $card-vertical-padding 0;
			}
		}

	}
}

/* Posts - Carousel Video Slideshow */
.posts--carousel {

	.posts__item {
		position: relative;
		overflow: hidden;
		text-align: center;
		margin: 0;
	}

		.posts__link-wrapper {
			display: inline-block;
			vertical-align: top;
			position: relative;
			text-align: left;

			&:hover {

				.posts__inner {
					transform: translateY(0);
				}

				&::before {
					transform: translateY(-100%);
				}
			}

			&::before {
				content:"\f04b";
				display: block;
				position: absolute;
				left: 50%;
				top: 50%;
				width: 40px;
				height: 40px;
				margin: -20px 0 0 -20px;
				background-color: rgba(23, 29, 33, .6);
				border-radius: 50%;
				border: 4px solid $color-primary;
				z-index: 1;
				color: #fff;
				font-size: 14px;
				text-align: center;
				line-height: 32px;
				text-indent: 3px;
				font-family: 'Font Awesome 5 Free';
				font-weight: 700;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				transition: transform 0.3s ease;
				pointer-events: none;
			}
		}
		.posts__inner {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			padding: 0 15px 18px 15px;
			transform: translateY(100%);
			transition: transform 0.3s ease-out;
			pointer-events: none;
		}
			.posts__title {
				color: #fff;
				margin-bottom: .25em;
				font-size: 12px;
				line-height: 1em;

				@media (min-width: 480px) {
					font-size: 12px;
				}

				@media (min-width: 768px) {
					font-size: 13px;
				}

				@media (min-width: 1199px) {
					font-size: 14px;
				}
			}

			.posts__thumb {
				float: none;
				margin: 0;
				background-color: #171d21;
				border-radius: 4px;

				img {
					border-radius: 4px;
					position: relative;
					transition: opacity 0.3s ease-in-out;
					backface-visibility: hidden;
					opacity: .6;
				}

				&:hover {

					img {
						opacity: .3;
					}
				}
			}

			.posts__date {
				color: $body-font-color;
				font-size: 9px;
				font-weight: 700;
				display: block;
				line-height: 1em;

				@media (min-width: 992px) {
					font-size: 10px;
				}
			}


	// Slick Carousel Navigation
	.slick-prev,
	.slick-next {
		top: -62px;

		&:hover {
			background-color: $color-2;
		}
	}
}


/* Posts: Inline (marquee) */
.posts--inline {
	margin: 0;

	.posts__item {

	}

	.posts__item,
	.posts__title,
	.posts__cat,
	.posts__excerpt {
		display: inline-block;
		margin: 0;
		font-size: 12px;
	}
		.posts__title {
			margin-right: .5em;

			@if $template != 'esports' {
				&::before {
					content: "//";
					margin-right: 1em;
					color: $color-4;
				}
			}
		}
		.posts__cat {
			position: relative;
			top: 3px;
		}
			.posts__cat-label {
				margin-bottom: 0;
			}
		.posts__excerpt {
			margin-right: 1em;

			@if $template == 'esports' {
				font-size: 11px;
			}
		}
}

/* Featured News - Football */
.posts--slider-var-width {

	.row {
		display: block;

		[class*="col-"] {
			float: left;
		}
	}
}

	.posts__item--desc-center {

		.posts__inner {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate3d(-50%,-50%,0); // needed translate3d to fix jumping issue in Chrome
			right: auto;
			background: none;
			text-align: center;
			width: 80%;
			padding: 0;
			z-index: 2;
			transition: transform 0.25s ease;

			@media (min-width: 992px) {
				padding: 0;
				width: 60%;
			}
		}

		.posts__thumb {
			border-radius: $card-border-radius;
			overflow: hidden;
			border: 1px solid $card-border-color;
			float: none;
			margin: 0 0 15px 0;
			transform: translate3d(0, 0, 0);

			img {
				width: 100%;
				height: auto;
			}
		}

		.posts__cat {
			overflow: visible;
		}
			.posts__cat-label {
				float: none;
				display: inline-block;
				font-size: 10px;
				box-shadow: 0 0 12px rgba(0,0,0,.3);
			}

		.posts__title {
			font-size: 14px;
			text-shadow: 0 0 12px rgba(0,0,0,.3);

			@media (min-width: 768px) {
				font-size: 20px;
			}

			@media (min-width: 992px) {
				font-size: 24px;
			}

			@media (min-width: 1199px) {
				font-size: 34px;
				line-height: 1em;
			}
		}

		.post-author__avatar {
			width: 24px;
			height: 24px;
			border-radius: 50%;
			margin-right: 10px;
		}
		.posts__date {
			font-size: 11px;
			color: $headings-color;
			text-shadow: 0 0 12px rgba(0,0,0,.3);
		}

		.posts__title-higlight {

			@if $template == football {
				color: $color-4;
			} @else {
				color: $color-primary;
			}
		}

		&:hover {

			.posts__inner {
				transform: translate3d(-50%,-58%,0);
			}
		}
	}



	.posts__item--desc-left {
		position: relative;

		.posts__inner {
			position: absolute;
			bottom: calc($card-vertical-padding / 1.5);
			left: $card-horizontal-padding;
			background: none;
			width: 80%;
			padding: 0;
			z-index: 2;
			transform: translate3d(0,0,0);
			transition: transform 0.25s ease;

			@media (min-width: 992px) {
				padding: 0;
				width: 85%;
			}

			@media (min-width: 1186px) {
				width: 75%;
			}
		}

		.posts__thumb {
			border-radius: $card-border-radius;
			overflow: hidden;
			border: 1px solid $card-border-color;
			float: none;
			margin: 0 0 14px 0;
			transform: translate3d(0, 0, 0);

			img {
				width: 100%;
				height: auto;
			}
		}

		.posts__cat {
			overflow: visible;
		}
			.posts__cat-label {
				display: inline-block;
				float: none;
				font-size: 10px;
				box-shadow: 0 0 12px rgba(0,0,0,.3);
			}

		.posts__title {
			font-size: 14px;
			line-height: 1em;
			margin-bottom: 0.1em;
			text-shadow: 0 0 12px rgba(0,0,0,.3);

			@media (min-width: 768px) {
				font-size: 16px;
			}

			@media (min-width: 992px) {
				font-size: 14px;
			}

			@media (min-width: 1199px) {
				font-size: 20px;
				line-height: 1em;
			}
		}

		.posts__date {
			font-size: 10px;
			color: $headings-color;
			text-shadow: 0 0 12px rgba(0,0,0,.3);
		}

		.posts__title-higlight {

			@if $template == football {
				color: $color-4;
			} @else {
				color: $color-primary;
			}
		}


		&:hover {

			.posts__inner {
				transform: translateY(-12px);
			}
		}

	}



/* Slider Featured */
.posts--slider-top-news {
	margin-bottom: 0 !important;

	.slick-list,
	.slick__slide {
		max-height: 660px !important;
	}

	.posts__thumb {
		float: none;
		margin: 0;

		img {
			width: 100%;
			height: auto;
		}
	}

	.posts__inner {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		width: 80%;
		z-index: 4;

		@media (min-width: 480px) {
			width: 50%;
		}

		@media (min-width: 768px) {
			width: 50%;
		}

		@media (min-width: 992px) {
			width: 620px;
		}

	}


	.posts__cat {
		visibility: visible;

		@media (min-width: 992px) {
			margin-bottom: 13px;
		}
	}
		.posts__cat-label {
			display: inline-block;
			float: none;
			box-shadow: 0 0 12px rgba(0,0,0,.3);

			@media (min-width: 992px) {
				font-size: 10px;
			}
		}

	.posts__title {
		font-size: 18px;
		line-height: 1em;
		text-shadow: 0 0 12px rgba(0,0,0,.3);

		@media (min-width: 768px) {
			font-size: 24px;
		}

		@media (min-width: 992px) {
			font-size: 34px;
		}

		@media (min-width: 1200px) {
			font-size: 42px;
		}

		> a {

			&:hover {
				color: #fff !important;
			}
		}
	}

		.posts__title-higlight {
			@if $template == basketball {
				color: $color-primary;
			} @else {
				color: $color-4-darken;
			}
		}

	.posts__footer {

		@media (max-width: 479px) {
			display: none;
		}
	}

	.post-author {
		display: inline-block;
	}
		.post-author__avatar {
			width: 24px;
			height: 24px;
			margin-right: 4px;
			border-radius: 50%;

			@media (min-width: 768px) {
				margin-right: 8px;
			}
		}
		.posts__date {
			color: #fff;
			font-size: 11px;
		}

	.post__meta {
		display: inline-block;
		margin-left: 1em;
		color: #fff;
		text-shadow: 0 0 12px rgba(0,0,0,.3);

		@media (min-width: 768px) {
			margin-left: 2.5em;
		}
	}
		.meta__item {

			a {
				color: #fff;
			}
		}



	// Has Image
	.posts__item-has-img {

		.posts__inner {

			@media (min-width: 768px) {
				text-align: left;
				transform: translate(-50%, -50%) translateX(35%);
			}
		}

		.posts__img-player {
			position: absolute;
			top: 0;
			bottom: 0;
			max-width: 45%;
			z-index: 4;

			@media (max-width: 767px) {
				display: none;
			}

			@media (min-width: 1200px) {
				left: 50%;
				top: 0;
				bottom: 0;
				transform: translate(-585px, 0);
			}

			img {

			}
		}
	}
}



/* Posts - Video Grid */
.posts--video-grid {

	.posts__item {
		position: relative;
		z-index: 4;
		overflow: hidden;
		margin-bottom: 15px;
		border: 1px solid $card-border-color;
		border-radius: $card-border-radius;

		&.posts__item--category-1 .posts__link-wrapper::before {
			border-color: $post-category-1;
		}

		&.posts__item--category-2 .posts__link-wrapper::before {
			border-color: $post-category-2;
		}

		&.posts__item--category-3 .posts__link-wrapper::before {
			border-color: $post-category-3;
		}
	}

	.posts__item--lg {

		@media (min-width: 992px) {

			.posts__link-wrapper {

				&::before {
					width: 80px;
					height: 80px;
					border-width: 6px;
					line-height: 68px;
					font-size: 32px;
				}
			}

			.posts__title {
				font-size: 24px;
				line-height: 1.08333em;
			}

			.posts__cat-label {
				font-size: 10px;
			}

		}
	}

		.posts__link-wrapper {
			display: block;
			vertical-align: top;
			position: relative;
			text-align: left;

			&:hover {

				.posts__inner {
					transform: translateY(0);
				}

				&::before {
					transform: translateY(-100%);
				}
			}

			&::before {
				content:"\f04b";
				display: block;
				position: absolute;
				left: 50%;
				top: 50%;
				width: 60px;
				height: 60px;
				margin: -30px 0 0 -30px;
				background-color: rgba(23, 29, 33, .6);
				border-radius: 50%;
				border: 4px solid $color-primary;
				z-index: 4;
				color: #fff;
				font-size: 24px;
				text-align: center;
				line-height: 52px;
				text-indent: 3px;
				font-family: 'Font Awesome 5 Free';
				font-weight: 700;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				transition: transform 0.3s ease;
				pointer-events: none;
			}
		}
		.posts__inner {
			position: absolute;
			left: 0;
			width: 75%;
			bottom: 0;
			padding: 0 $card-horizontal-padding 18px $card-horizontal-padding;
			transform: translateY(100%);
			transition: transform 0.3s ease-out;
			pointer-events: none;
			z-index: 4;
		}
			.posts__title {
				color: #fff;
				margin-bottom: .25em;
				font-size: 12px;
				line-height: 1em;

				@media (min-width: 480px) {
					font-size: 12px;
				}

				@media (min-width: 768px) {
					font-size: 13px;
				}

				@media (min-width: 1199px) {
					font-size: 14px;
				}
			}

			.posts__thumb {
				float: none;
				margin: 0;
				background-color: #171d21;
				border-radius: 4px;

				img {
					width: 100%;
					height: auto;
					border-radius: 4px;
					position: relative;
					transition: opacity 0.3s ease-in-out;
				}
			}

			.posts__date {
				color: $body-font-color;
				font-size: 9px;
				font-weight: 700;
				display: block;
				line-height: 1em;

				@media (min-width: 992px) {
					font-size: 10px;
				}
			}
}


// Grid Layout
.grid-layout {
	display: grid;
	justify-content: stretch;
	grid-column-gap: 0;
	grid-row-gap: 0;
}

// Grid Layout: 1-2-1
.grid-layout--1-2-1 {
	grid-template-columns: 1fr;
	grid-template-rows: repeat(4, 1fr);

	@media (min-width: 480px) {
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
	}

	@media (min-width: 992px) {
		grid-template-columns: 43% 1fr 1fr;
		grid-template-rows: 360px 260px;
	}

	.post-grid__item:nth-child(1) {

		@media (min-width: 992px) {
			grid-row-start: 1;
			grid-row-end: 3;
		}
	}
	.post-grid__item:nth-child(4) {

		@media (min-width: 992px) {
			grid-column-start: 2;
			grid-column-end: 4;
		}
	}
}


// Post Card Grid
.posts__item--card-grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto;
	grid-template-areas:
		"thumb thumb"
		"inner inner"
		"footer footer";

		@media (min-width: 576px) and (max-width: 767px) {
			grid-template-columns: 1fr 2fr;
		}

		@media (min-width: 576px) {
			grid-template-areas:
				"thumb inner"
				"footer footer";
		}

		.posts__thumb {
			grid-area: thumb;

			@media (min-width: 375px) {
				height: auto;
			}

			@media (min-width: 768px) {
				max-width: none;
			}

			@media (min-width: 992px) {
				max-width: none;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			.posts__cta {
				inset-inline-start: 13px;
				inset-inline-end: auto;
			}
		}
		.posts__inner {
			grid-area: inner;
			padding-top: 0;

			@media (min-width: 992px) {
				padding-top: 0;
			}

			@media (min-width: 1200px) {
				padding-left: 16px;
			}
		}
		.posts__footer {
			grid-area: footer;
		}
}

// Post Card Grid - Full width Thumbnail
.posts__item--card-grid-fullw-thumb {
	grid-template-columns: 1fr;
	grid-template-areas:
		"thumb"
		"inner"
		"footer";

		@media (min-width: 576px) and (max-width: 767px) {
			grid-template-columns: 1fr;
		}

		@media (min-width: 576px) {
			grid-template-areas:
				"thumb"
				"inner"
				"footer";
		}

	.posts__cta {

		@media (min-width: 992px) {
			right: 32px;
		}
	}

	.post__meta {

		&.post__meta--row {

			@media (min-width: 992px) {
				margin-bottom: 18px;
			}
		}
	}

	.posts__inner {
		padding: 0;
	}
		.card__content {

			@media (min-width: 992px) {
				padding: 32px;
			}
		}

		.posts__title {
			line-height: 1em;

			@media (min-width: 576px) {
				font-size: $font-size-h3;
			}

			@media (min-width: 992px) {
				font-size: 32px;
				margin-bottom: 0;
			}

			@media (min-width: 1200px) {
				font-size: $font-size-h2;
			}
		}

		.posts__excerpt {
			padding-bottom: 0;
		}

	// Change colors on dark background
	.section--dark & {

		.posts__title {
			color: $color-white;
		}
	}
}

// Post Card Grid - Full width Thumbnail (Overlay)
.posts__item--card-grid-fullw-thumb-overlay {
	grid-template-rows: 154px 168px auto;
	margin-block-end: 0;

	@media (min-width: 767px) {
		grid-template-rows: 184px 200px auto;
	}

	@media (min-width: 992px) {
		grid-template-rows: 230px 250px auto;
	}

	.posts__thumb {
		grid-area: 1 / 1 / 3 / -1;
	}

	.posts__inner {
		grid-area: 1 / 1 / 3 / -1;
		will-change: transform;
		display: flex;
		align-items: flex-end;

		.card__content {
			padding-block-start: 0;

			@media (min-width: 992px) {
				padding-block-start: 0;
			}
		}
	}

		.posts__thumb {

			&::before {
				content: "";
				display: block;
				position: absolute;
				z-index: 1;
				inset: auto 0 0 0;
				height: 70%;
				background-image: linear-gradient(to bottom, transparent, rgba(0,0,0,.9));
				pointer-events: none;
			}
		}
			.posts__cta {
				transform: none;
				inset-inline-end: $card-horizontal-padding;
				inset-block-start: $card-vertical-padding;
			}

		.posts__title {
			a {
				color: $color-white;

				&:hover {
					color: $color-white;
					opacity: .8;
				}
			}
		}

		.posts__excerpt {
			color: $color-gray;

			@media (max-width: 767px) {
				display: none;
			}
		}

		.post__meta {
			color: $color-gray;
		}

	.posts__footer {
		grid-area: 3 / 1 / -1 / -1;
	}
}


// Content
.posts__item--card-grid-content-sidebar {
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto auto;
	grid-template-areas:
		"thumb thumb"
		"inner inner";

		@media (min-width: 576px) and (max-width: 767px) {
			grid-template-columns: 1fr 1fr;
		}

		@media (min-width: 576px) {
			grid-template-areas:
			"thumb thumb"
			"inner inner";
		}

		@media (min-width: 992px) {
			grid-template-columns: 2fr 1fr;
			grid-template-areas:
			"thumb inner"
			"thumb inner";
		}

		.posts__title {
			line-height: 1.18em;

			@media (min-width: 576px) {
				font-size: $font-size-h3;
			}

			@media (min-width: 992px) {
				font-size: $font-size-h3;
				margin-bottom: 0;
			}
		}
}

// Posts Related - Skewed
.posts--related-skewed {

	.posts__thumb {

		> .stretched-link {

			&::after {
				z-index: 2;
			}
		}

		&::after {
			transition: opacity .3s ease;
		}

		&:hover {

			&::after {
				opacity: .4;
			}
		}
	}

	.posts__item {

		.posts__inner {
			padding: 0 24px 20px 24px;

			@media (min-width: 576px) {
				padding: 0 24px 20px 24px;
				max-width: 80%;
			}

			@media (min-width: 768px) {
				padding: 0 30px 20px 30px;
				max-width: 75%;
			}

			@media (min-width: 992px) {
				padding: 0 40px 30px 40px;
			}

			@media (min-width: 1200px) {
				padding: 0 70px 55px 70px;
			}

			@media (min-width: 1600px) {
				max-width: 55%;
			}
		}
	}

	.post-grid__item {

		.posts__item {
			@media (min-width: 576px) {
				clip-path: polygon(0 0, 100% 0%, 80% 100%, 0% 100%);
				margin-right: -11.5%;
			}
		}
	}

	.post-grid__item + .post-grid__item {

		.posts__item {

			@media (min-width: 576px) {
				clip-path: polygon(20% 0, 100% 0%, 100% 100%, 0 100%);
				margin-right: 0;
				margin-left: -11.5%;

				.posts__inner {
					left: auto;
					right: 0;
					text-align: right;
				}
					.post__meta--row {
						justify-content: flex-end;
					}
			}
		}
	}

}


// Video Posts
.posts__item--stacked {
	position: relative;

	.posts__inner {
		position: absolute;
		left: 18px;
		right: 18px;
		bottom: 18px;
	}

		.posts__title {
			font-size: 14px;
			line-height: 1.42em;
			color: $color-white;
			margin-bottom: .3em;

			> a {
				color: $color-white;
			}
		}

		.meta__item {
			color: $color-gray;
		}
}
