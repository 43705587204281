/* Card
/* ------------------------- */

.card {
	border: 1px solid $card-border-color;
	border-radius: $card-border-radius;
	background-color: $card-bg;
	margin-bottom: $card-margin-vertical;
}
.card--row {
	flex-direction: row;
}
.card--block {
	display: block;
}
.card--clean {
	background: none;
	border: none;

	.card__header {
		background-color: $card-bg;
		border: 1px solid $card-border-color;
		border-radius: $card-border-radius;
	}

	> .card__content {
		padding: $card-margin-vertical 0 0 0; // leave only top padding
	}
}
.card--has-table {

	> .card__content {
		padding: 0;

		.table {
			margin-bottom: 0;
		}
	}
}
.card--no-paddings {

	> .card__content {
		padding: 0 !important;

		@media (min-width: 992px) {
			padding: 0 !important;
		}
	}
}
.card--squared-top {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

	.card__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		row-gap: calc($card-vertical-padding / 2);
		min-height: $card-header-min-height;
		padding: $card-header-padding-top $card-horizontal-padding;
		border-top-left-radius: $card-border-radius - 1;
		border-top-right-radius: $card-border-radius - 1;
		position: relative;
		border-bottom: 1px solid $card-border-color;
		overflow: hidden;
		background-color: $card-header-bg;

		& ~ & {
			border-radius: 0;

			&::before {
				border-radius: 0;
			}
		}

		&::before {
			content:"";
			display: block;
			position: absolute;
			width: $card-header-border-width;
			left: 0;
			top: 0;
			bottom: 0;
			background-color: $color-primary;
			border-radius: $card-border-radius - 1px 0 0 0 ;
		}

		> h4 {
			font-size: $card-header-font-size;
			line-height: 1.2em;
			letter-spacing: -0.02em;
			margin-bottom: 0;
			text-transform: $card-header-text-transform;
			font-style: $font-style-h4;
		}

		.card-header__button {

			@media (min-width: 992px) {
				float: right;
			}
		}
	}
	.card__header--has-btn {
		padding-top: 24px;
		padding-bottom: 24px;

		> h4 {
			margin-bottom: .5em;

			@media (min-width: 480px) {
				display: inline-block;
				margin-bottom: 0;
			}
		}
	}
		// Secondary Header Content
		.card__header-secondary {
			display: flex;
			gap: 12px;

			.form-group {
				margin-bottom: 0;
			}
		}

	.card__header--has-filter {
		padding-top: 24px;
		padding-bottom: 24px;

		> h4 {
			display: inline-block;
		}

		.category-filter {
			padding-top: 5px;

			@media (min-width: 480px) {
				float: right;
				padding-top: 0;
			}
		}
	}
	.card__header--has-legend {
		padding-top: 24px;
		padding-bottom: 24px;

		> h4 {
			display: inline-block;
		}

		.chart-legend {
			padding-top: 5px;

			@media (min-width: 480px) {
				float: right;
				padding-top: 0;
			}
		}
	}
	.card__header--has-checkbox {
		padding-top: 24px;
		padding-bottom: 24px;

		> h4 {
			margin-bottom: .5em;

			@media (min-width: 480px) {
				display: inline-block;
				margin-bottom: 0;
			}
		}

		.checkbox {

			@media (min-width: 480px) {
				float: right;
				padding-top: 0;
				font-weight: bold;

				@if $template == 'hockey' {
					font-size: 12px;
				} @else {
					font-size: 10px;
				}
			}
		}
	}

	.card__header--has-toggle {
		padding-top: 24px;
		padding-bottom: 24px;
		display: flex;
		align-items: center;

		.switch {
			flex-grow: 1;
			text-align: right;
		}
	}

	.card__header--has-arrows {

		.slick-prev,
		.slick-next {
			top: 25px;
		}
	}

	.card__header--has-team-info {
		padding-top: 21px;
		padding-bottom: 21px;
	}

	.card__header--no-highlight {

		&::before {
			display: none;
		}
	}

	// Double Header
	.card__header--double {
		display: flex;
		justify-content: space-between;

		&::after {
			content:"";
			display: block;
			position: absolute;
			width: 6px;
			right: 0;
			top: 0;
			bottom: 0;
			background-color: $color-4-darken;
			border-radius: 0 3px 0 0;
		}
	}

	// Hero
	.card__header--hero {
		position: relative;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		min-height: 140px;

		.card__header-hgroup {
			position: relative;
			z-index: 4;
			text-align: center;
		}
			.card__header-title {
				font-size: 30px;
				line-height: 1.2em;
				text-transform: uppercase;
				color: $color-white;
				margin-block-end: .4em;
			}
			.card__header-subtitle {
				font-size: $font-size-h6;
				line-height: 1.2em;
				text-transform: uppercase;
				color: $color-white;
				margin-block-end: 0;
			}
		.card__header-bg {
			position: absolute;
			inset: 0;
			background-image: url("../images/hockey/samples/ticket-bg-1.jpg");
			background-repeat: no-repeat;
			background-position: 50% 0;
			background-size: cover;
			isolation: isolate;
		}
		.card__header-bg--2 {
			background-image: url("../images/hockey/samples/ticket-bg-2.jpg");
		}
	}


	.card__content {
		padding: $card-vertical-padding $card-horizontal-padding;
	}
	.card__content-inner {
		padding: 20px;
	}
	.card__content--header-bg {
		background-color: $card-header-bg;
	}
	.card__content--pattern-dotted {
		background-image: $card-bg-dotted;
		background-position: 6px 10px;
	}

	.card__subheader {
		background: $card-subheader-bg;
		border-top: 1px solid $card-border-color;
		border-bottom: 1px solid $card-border-color;
		text-align: center;
		padding: 8px 0;
		margin-inline: -$card-horizontal-padding;
		padding-inline: $card-horizontal-padding;
		margin-bottom: 20px;

		@media (min-width: 992px) {
			padding-block: 11px;
			padding-inline: $card-horizontal-padding;
			margin-bottom: 30px;
		}

		h5 {
			margin-bottom: 0;
			font-style: normal;
			text-transform: uppercase;
		}
	}
	.card__subheader--nomargins {
		margin: 0;
	}

	.card__subheader--sm {

		@media (min-width: 992px) {
			margin-bottom: $card-vertical-padding;
		}

		h5 {
			font-size: 11px;
			line-height: 1.2em;

			@if $template == 'hockey' {
				font-size: 10px;
			} @else {
				font-size: 11px;
			}
		}
	}
	.card__subheader--side {
		text-align: left;
		border-top: 0;
		background-color: transparent;
		padding: 15px $card-horizontal-padding;

		h5 {
			font-style: italic;
		}
	}
	.card__subheader--flex {
		display: flex;
		border-top: 1px solid $card-border-color;

		:last-child {
			margin-left: auto;
		}

		h5 {
			font-style: normal;
		}
	}
	.card__subheader--clean {
		background-color: transparent;

		> h5 {
			font-weight: 400;
			color: $body-font-color;
			font-size: 9px;
		}
	}



// Card Header for Teams Legends
.alc-teams-legend {
	display: flex;
	align-items: center;
}
	.alc-teams-legend__heading {

	}
		.alc-teams-legend__title {

		}
		.alc-teams-legend__info {
			margin-left: auto;

			@media (min-width: 992px) {
				display: flex;
			}
		}

		.alc-teams-legend__teams {
			display: flex;
			flex-wrap: nowrap;
			align-items: center;

			@media (max-width: 991px) {
				margin-bottom: 10px;
			}

			& + .btn {
				margin-left: 20px;
			}
		}
			.alc-teams-legend__team {
				display: flex;
				align-items: center;

				& + & {
					margin-left: 20px;
				}
			}
				.alc-teams-legend__team-color {
					width: 8px;
					height: 8px;
					background-color: $color-primary;
					border-radius: calc($card-border-radius / 2);
					margin-right: 10px;
				}
				.alc-teams-legend__team-color--color-info {
					background-color: $color-info;
				}
				.alc-teams-legend__team-color--color-success {
					background-color: $color-success;
				}
				.alc-teams-legend__team-color--color-4 {
					background-color: $color-4;
				}
				// for demo purpose
				.alc-teams-legend__team-color--color-clovers {
					background-color: #aaf20e;
				}
				.alc-teams-legend__team-color--color-ocean-kings {
					background-color: #1b96ff;
				}

				.alc-teams-legend__team-logo {

				}
				.alc-teams-legend__team-title {
					margin-bottom: 0;
					text-transform: none;
				}

// Sizes
.card--lg {

	.card__content {

		@media (min-width: 992px) {
			padding: $card-vertical-padding-lg $card-horizontal-padding-lg;
		}
	}
}

.card--xlg {

	.card__content {

		@media (min-width: 992px) {
			padding: $card-vertical-padding-xlg $card-horizontal-padding-xlg;
		}
	}
}

// Color
.card--alt-color {

	.card__header {

		&::before {

			@if $template == soccer {
				background-color: $color-4-darken;
			} @else {
				background-color: $color-4;
			}

		}
	}
}


// for demo purpose
.card--color-clovers {

	.card__header {

		&::before {
			background-color: #aaf20e;
		}
	}
}

.card--color--info {

	.card__header {

		&::before {
			background-color: $color-info;
		}
	}
}

// Shop
.card__header--shop-filter {
	padding: 0;
}


// Full Width
.card--section {
	border-radius: 0;
	border-left: none;
	border-right: none;
}

// Card
.card--info {

	.card__content {

		p {
			font-size: .86em;
			line-height: 1.83em;
		}
	}

	.contact-info {
		display: flex;
		flex-wrap: wrap;
		border-top: 1px solid $card-border-color;
	}
		.contact-info__item {
			flex-grow: 1;
			max-width: 100%;
			flex-basis: 0;
			margin: -1px 0 0 -1px;
			text-align: center;
			padding: 25px 10px;
			border-left: 1px solid $card-border-color;
			border-top: 1px solid $card-border-color;
		}
			.contact-info__icon {
				color: $color-primary;
				font-size: 18px;
				margin-bottom: 20px;
			}
			.contact-info__label {
				font-size: .86em;
				font-family: $font-family-accent;
				font-weight: 700;
				color: $headings-color;

				a {
					color: $headings-color;

					&:hover {
						color: $link-color-hover;
					}
				}
			}
}


// Card Media Block
.card__media-block {

	.card__img {

	}
	.card__content {

		@media (min-width: 992px) {
			padding-top: 45px;
			padding-bottom: 45px;
			padding-right: 40px;
		}

		p:last-child {
			margin-bottom: 0;
		}
	}
}
