/*
// #Content
// --------------------------------------------------
*/

.site-content,
.section {
	padding: $content-padding-vertical-sm 0;

	@media (min-width: 992px) {
		padding: $content-padding-vertical 0;
	}
}

.section--bg1 {
	background-color: $color-dark-lighten;
}
.section--bg2 {
	background-image: linear-gradient(to bottom, $body-bg-color, #fff 120px);
}

.section--no-paddings {
	padding: 0;

	@media (min-width: 992px) {
		padding: 0;
	}
}

.section--dark {
	background-color: #000;
}
