/*
// #Social Links
// --------------------------------------------------
*/

.social-links {
	list-style-type: none;
	padding: 0;
	margin: 0;
}
	.social-links__item {
		display: inline-block;
		font-size: 9px;
		text-transform: uppercase;
		font-family: $font-family-accent;
		margin-right: 12px;

		i {
			font-size: 12px;
			margin-right: 5px;
			color: #fff;
			transition: color 0.2s ease;
		}

		&:last-child {
			margin-right: 0;
		}
	}
		.social-links__link {
			display: inline-block;
			color: #6b6d70;
			transition: color 0.2s ease;

			&:hover {
				color: #fff;

				i {
					color: $color-primary;
				}
			}
		}

// Social Links in Main Nav
.social-links--main-nav {
	display: inline-block;
	padding-left: 30px;

	.social-links__item {
		margin-right: 19px;

		@media (min-width: 992px) and (max-width: 1199px) {
			margin-right: 10px;
		}
	}

	@media (min-width: 992px) and (max-width: 1199px) {
		padding-left: 30px;
	}
}

// Social Links in the Top Bar
.social-links--top-bar {
	padding-left: 0;

	.social-links__item {
		margin-right: 20px;

		i {
			font-size: 16px;
			opacity: .6;
		}
	}
		.social-links__link {

			i {
				color: $top-bar-social-icon-color;
			}

			&:hover {

				i {
					opacity: 1;
					color: $top-bar-social-icon-color-hover;
				}
			}
		}
}

// Social Links Buttons
.social-links--btn {

	.social-links__item {
		margin: 0 0 0 0;
	}
		.social-links__link {
			display: inline-block;
			width: 26px;
			height: 26px;
			line-height: 26px;
			text-align: center;
			color: #fff;
			border-radius: 2px;
			font-size: 12px;
			margin: 0 2px;
			transition: opacity 0.2s ease-in-out;

			&:hover {
				opacity: 0.8;

				i {
					color: #fff;
				}
			}

			i {
				margin: 0;
			}
		}

		.social-links__link--lg {
			width: 32px;
			height: 32px;
			line-height: 32px;
			margin: 0 6px;
		}

		// Facebook
		@each $color, $value in $social-links {
			.social-links__link--#{$color} {
				background-color: $value;
			}
		}

	&.social-links--btn-block {

		.social-links__item {
			display: inline;
		}

			.social-links__link {
				display: block;
				width: 100%;
				padding-left: 0;
				padding-right: 0;
				margin-bottom: 12px;
				font-size: 9px;
				height: 24px;
				line-height: 24px;
			}
	}
}

// Social Links - Buttons
.social-links--btn-plus {
	display: flex;
	flex-wrap: wrap;
	min-width: 0;
	gap: 12px;

	.social-links__item {
		margin: 0;
	}

		.social-links__link {
			position: relative;
			width: auto;
			height: auto;
			display: flex;
			flex-wrap: nowrap;
			justify-content: flex-start;
			align-items: center;
			font-size: $base-font-size * 0.643;
			line-height: 1.2em;
			border-radius: 0;
			text-align: start;
			padding: .78em 4em .78em 1.33em;
			overflow: hidden;
			margin: 0;

			> i {
				margin-right: .67em;
			}

			&:hover {

				.social-links__icon {

					> i {
						transform: rotate(90deg);
					}
				}
			}
		}

			.social-links__label {
				padding: 0 .67em 0 0;
				font-weight: 700;
			}

			.social-links__icon {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 3.33em;

				&::before {
					content: "";
					display: block;
					position: absolute;
					left: 0;
					right: -20%;
					top: 0;
					bottom: 0;
					background-color: rgba(0,0,0,.1);
					transform: skewX(-20deg);
				}

				> i {
					position: relative;
					transition: transform .2s ease-in;
				}
			}
}

// Social Links Circle
.social-links--circle {

	.social-links__item {
		margin-right: 20px;

		@media (min-width: 992px) {
			margin-right: 10px;
		}

		@media (min-width: 1200px) {
			margin-right: 24px;
		}

		&:last-child {
			margin-right: 0;
		}
	}
		.social-links__link {
			border: 2px solid rgba($body-font-color, 0.4);
			border-radius: 50%;
			width: 34px;
			height: 34px;
			text-align: center;
			transition: border-color 0.3s ease;

			i {
				margin-right: 0;
				font-size: 14px;
				line-height: 30px;
			}

			&:hover {
				border-color: $color-4;

				i {
					color: #fff;
				}
			}
		}
}


// Social Links Circle Filled
.social-links--circle-filled {

	.social-links__item {
		margin-right: 10px;

		@media (min-width: 992px) {
			margin-right: 10px;
		}

		@media (min-width: 1200px) {
			margin-right: 18px;
		}

		&:last-child {
			margin-right: 0;
		}
	}
		.social-links__link {
			border-radius: 50%;
			width: 40px;
			height: 40px;
			text-align: center;
			transition: opacity 0.3s ease;

			i {
				margin-right: 0;
				font-size: 14px;
				line-height: 40px;
			}

			&:hover {
				opacity: .8;

				i {
					color: #fff;
				}
			}
		}

		// Facebook
		.social-links__link--facebook {
			background-color: $social-facebook;
		}
		// Twitter
		.social-links__link--twitter {
			background-color: $social-twitter;
		}
		// Google
		.social-links__link--google {
			background-color: $social-google;
		}
		// Twitch
		.social-links__link--twitch {
			background-color: $social-twitch;
		}
}


// Social Links - Simple
.social-links--plain {

	.social-links__link {

		i {
			color: $color-primary;
		}
	}

	@each $color, $value in $social-links {
		.social-links__link--#{$color} {

			i {
				color: $value;
			}
		}
	}
}
