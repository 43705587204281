/* Event: Form Events */
.sp-form-events {
	display: flex;
}
	.sp-form-event-link {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		font-size: 8px;
		height: 1.5em;
		width: 1.5em;
		line-height: 1.5em;
		text-align: center;
		margin-inline: 0.25em;
		color: $color-white;
		background-color: $color-gray;
		z-index: 1;

		&:hover {
			color: $color-white;
		}
	}
	.sp-form-event-link--win {
		background-color: $color-warning;
	}
