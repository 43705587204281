/* Account User */
.df-account-user {
	display: flex;
}
	.df-account-user__avatar {
		flex-shrink: 0;
		flex-basis: 100px;
		aspect-ratio: 1;
		object-fit: cover;
	}
	.df-account-user__body {
		padding-inline-start: 20px;
		display: flex;
		flex: 1;
		flex-direction: column;
		justify-content: space-between;
	}
		.df-account-user__title {
			font-size: $font-size-h4;
			margin-bottom: .25em;
		}
		.df-account-user__subtitle {
			font-size: $font-size-sm;
			line-height: 1.2em;
		}
		.df-account-user__info {
			list-style: none;
			padding: 0;
			margin: 0;
			font-size: 10px;
			line-height: 1.2em;
			display: flex;
			flex-wrap: wrap;
			row-gap: 10px;
		}
			.df-account-user__info-item {
				flex-basis: 50%;
			}
				.df-account-user__info-item-label {

				}
				.df-account-user__info-item-value {
					color: $headings-color;
					font-weight: bold;
				}
