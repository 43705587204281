/* Player Info Banner */
.alc-player-info-banner {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-template-rows: auto 1fr;
	column-gap: 30px;
	background-color: $card-bg;
	border: 1px solid $card-border-color;

	@media (max-width: 991px) {
		column-gap: 0;
	}
}

	// Header
	.alc-player-info-banner__header {
		grid-column: 2 / 6;
		grid-row: 1 / 2;
		padding: 45px 0 0 0;
		margin-bottom: 40px;
		z-index: 2;

		@media (max-width: 991px) {
			grid-column: 7 / -2;
			padding: 0;
			margin: 0;
			align-self: center;
		}

		@media (max-width: 767px) {
			grid-column: 8 / -1;
		}
	}
		.alc-player-info-banner__player-label {
			font-size: 10px;
			line-height: 1.2em;
			margin-bottom: .25em;

			@media (min-width: 992px) {
				color: $color-gray;
			}
		}
		.alc-player-info-banner__player-title {
			font-size: 32px;
			line-height: 1em;
			margin-bottom: 0;

			@media (min-width: 768px) {
				font-size: $font-size-h2;
			}

			@media (min-width: 992px) {
				color: $headings-color-inverse;
				font-size: $font-size-h2;
			}
		}

	// Meta Info
	.alc-player-info-banner__meta {
		grid-column: 2 / 5;
		grid-row: 2 / -1;
		z-index: 2;
		padding-bottom: 40px;

		@media (max-width: 991px) {
			grid-column: 1 / 7;
			grid-row: 2 / 3;
			padding-left: $card-horizontal-padding;
			border-top: 1px solid $card-border-color;
		}

		.alc-player-info-banner__list-info {

			&.list-info {

				.list-info__label {

					@media (min-width: 992px) {
						color: $color-gray;
					}
				}

				.list-info__value {

					@media (min-width: 992px) {
						color: $headings-color-inverse;
					}
				}
			}
		}
	}

		.alc-player-info-banner__meta-header {

		}
		.alc-player-info-banner__meta-content {

		}

			.alc-player-info-banner__list-info {

				&.list-info {
					list-style-type: none;
					padding: 0;
					margin: 0;
					display: grid;
					grid-template-columns: repeat(3, 1fr);
					column-gap: 30px;
					row-gap: 24px;

					@media (max-width: 991px) {
						padding-top: $card-vertical-padding;
						grid-template-columns: 1fr;
					}
				}
					.list-info__item {

					}
					.list-info__item--lg {

						@media (min-width: 992px) {
							grid-column: span 2;
						}
					}
						.list-info__label {
							font-size: 10px;
							line-height: 1.2em;
							margin-bottom: .5em;
						}
						.list-info__value {
							font-size: $font-size-sm;
							line-height: 1.2em;
							color: $headings-color;
							font-weight: bold;
						}
						.list-info__flag {
							margin-right: .5em;
						}
			}

	.alc-player-meta {

	}

	// Photo
	.alc-player-info-banner__img {
		grid-column: 5 / 9;
		grid-row: 1 / -1;
		justify-self: center;
		align-self: end;
		margin-top: -20px;
		z-index: 2;

		@media (max-width: 991px) {
			grid-column: 1 / 7;
			grid-row: 1 / 2;
		}
	}

	// Statistics
	.alc-player-info-banner__stats {
		grid-column: -2 / -5;
		grid-row: 2 / -1;
		z-index: 2;
		padding-bottom: 40px;

		@media (max-width: 991px) {
			grid-column: 7 / -1;
			grid-row: 2 / 3;
			padding-right: $card-horizontal-padding;
			border-top: 1px solid $card-border-color;
		}

		.alc-player-info-banner__list-info {

		}
	}

	// Background
	.alc-player-info-banner__bg {
		grid-column: 1 / 9;
		grid-row: 1 / -1;
		background-image: url("../images/hockey/samples/single-player-bg.png");
		background-position: 0 50%;
		background-size: cover;
		clip-path: polygon(0 0, calc(100% - 62px) 0, calc(100% - 186px) 100%, 0% 100%);

		@media (max-width: 991px) {
			grid-row: 1 / 2;
			clip-path: polygon(0 0, 76% 0, 46% 100%, 0% 100%);
		}
	}
