/*
// #Page Heading
// --------------------------------------------------
*/

.page-heading {
	background-color: $page-heading-bg-color;
	background-image: $page-heading-bg-image-url;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	text-align: center;
	padding: $page-heading-padding-sm;
	position: relative;

	@media (min-width: 992px) {
		padding: $page-heading-padding-lg;
	}

	.breadcrumb {
		@include font-accent($breadcrumb-font-size);
		text-transform: $breadcrumb-text-transform;
		font-weight: $breadcrumb-font-weight;
		padding: 0;

		@media (min-width: 992px) {
			font-size: 11px;
			padding: 3px 0;
		}

		> li {
			padding-left: $breadcrumb-item-padding;

			+ li::before {
				padding-right: $breadcrumb-item-padding;
				color: $body-font-color;
				font-weight: normal;
				content: escape-svg($breadcrumb-divider);
			}

			> a {
				color: $breadcrumb-link-color;

				&:hover {
					color: $color-primary;
				}
			}
		}
	}

	&::before {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background-image: $page-heading-bg-pattern-url;
		background-size: $page-heading-bg-pattern-size;
		background-position: 100% 0;
		background-repeat: $page-heading-bg-pattern-repeat;
		opacity: $page-heading-bg-pattern-opacity;
	}

	// Duotone effect
	&.effect-duotone {

		.container {
			position: relative;
			z-index: 3;
		}
	}
}

// Horizontal
.page-heading--horizontal {
	text-align: inherit;

	@media (max-width: 767px) {
		&:not(.page-heading--title-highlight) .row {
			display: block;
			text-align: center;
		}
	}
}
	.page-heading__title {
		color: $page-heading-font-color;
		letter-spacing: -0.02em;
		font-size: $page-heading-font-size-sm;
		margin-bottom: 0;
		font-style: $page-heading-font-style;
		text-transform: $page-heading-text-transform;

		@media (min-width: 992px) {
			font-size: $page-heading-font-size;
		}

		.highlight {
			color: $page-heading-font-highlight;
		}
	}

// Single Post Background
.page-heading--post-bg {
	background-image: $single-post-3-image;
}

// Page Heading with Overlay
.page-heading--overlay {
	position: relative;
	overflow: hidden;

	&::before {
		content:"";
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		background-image: none;
		background-color: rgba(0,0,0,.6);
		opacity: 1;
	}
}

// Page Heading with Duotone
.page-heading--duotone {
	position: relative;
	overflow: hidden;

	&::before {
		content:"";
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		background-image: none;
		background-color: transparent;
	}
}

// Page Heading without Pattern
.page-heading--simple {
	height: 240px;
	overflow: hidden;

	&::before {
		display: none;
	}

	@media (min-width: 768px) {
		height: 320px;
	}

	@media (min-width: 992px) {
		height: 360px;
	}

	@media (min-width: 1200px) {
		height: 420px;
	}

	@if $template == 'esports' {
		background-image: url("../images/esports/samples/single-post-img9.jpg");
	}

	.page-heading__img {
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center center;
	}
}

// Page Heading - Hockey style
.page-heading--title-highlight {
	padding: 0;

	@media (min-width: 992px) {
		padding: 0;
	}

	&::before {
		background-color: $color-primary-darken;
		right: auto;
		width: 35px;

		@media (min-width: 576px) {
			width: calc(50% - 524px / 2 + 35px);
		}

		@media (min-width: 768px) {
			width: calc(50% - 704px / 2 + 35px);
		}

		@media (min-width: 992px) {
			width: calc(50% - 944px / 2 + 35px);
		}

		@media (min-width: 1200px) {
			width: calc(50% - 1170px / 2 + 35px);
		}
	}

	[class*="col"] {
		display: flex;
	}

	.page-heading__inner {
		position: relative;
		display: flex;
		align-items: center;
	}
	.page-heading__inner--highlight {
		background-color: $color-primary-darken;
		padding-right: 40px;
		position: relative;
		transform: skew(-20deg);

		@media (min-width: 992px) {
			padding-right: 80px;
		}

		.page-heading__title {
			transform: skew(20deg);
		}
	}

		.page-heading__title,
		.page-heading__breadcrumb {
			padding: $page-heading-padding-sm;

			@media (min-width: 992px) {
				padding: $page-heading-padding-lg;
			}
		}

		.page-heading__breadcrumb {

		}
}
