/* Accordion
/* ------------------------- */

.accordion {

	.card {
		margin-bottom: 0;

		&:first-of-type {

			.card__header {
				border-top-right-radius: $card-border-radius;
			}
		}

		&:last-of-type {

			.card__header {
				border-bottom-right-radius: $card-border-radius;
			}
		}
	}

		// Accordion Header
		.card__header {
			padding: 0;
			border-radius: 0;

			&::before {
				display: none;
			}

			> h5 {
				flex-basis: 100%;
			}
		}
			.accordion__header-link {
				display: block;
				width: 100%;
				text-align: left;
				border: none;
				position: relative;
				padding: 0;
				font-style: normal;
				font-size: 14px;
				padding: $card-vertical-padding $card-horizontal-padding + 70px $card-vertical-padding $card-horizontal-padding;
				color: $headings-color;

				&:hover {
					color: $link-color;
				}

				&:not(.collapsed) {

					.accordion__header-link-icon {
						background-color: $accordion-plus-bg-hover;
						border-bottom-right-radius: 0;

						&::after {
							opacity: 0;
						}
					}
				}
			}
				.accordion__header-link-icon {
					display: block;
					position: absolute;
					right: 0;
					top: 0;
					bottom: 0;
					width: 70px;
					background-color: $accordion-plus-bg;
					border-radius: 0;
					// border-bottom-right-radius: $card-border-radius;

					&::before,
					&::after {
						content: "";
						display: block;
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
						background-color: $color-white;
						transition: opacity .2s ease;
					}

					// horizontall line
					&::before {
						width: 12px;
						height: 2px;
					}

					// vertical line
					&::after {
						width: 2px;
						height: 12px;
					}
				}

		// Accordion Content
		.card__content {

			p:last-child {
				margin-bottom: 0;
			}
		}
}

// Spaced
.accordion--space-between {

	.card {
		margin-bottom: 15px;

		&:last-child {
			margin-bottom: 0;
		}

		&,
		&:first-of-type,
		&:last-of-type {

			.card__header {
				border-top-right-radius: $card-border-radius;
				border-bottom-right-radius: 0;
			}
		}
	}
		.card__header {

		}

	// Overwrite Bootstrap default styling
	.card:not(:first-of-type):not(:last-of-type) {
		border-bottom: 1px solid $card-border-color;
		border-radius: $card-border-radius;
	}

	.card:not(:first-of-type) {
		.card-header:first-child {
			border-radius: $card-border-radius;
		}
	}

	.card:first-of-type {
		border-bottom: 1px solid $card-border-color;
		border-bottom-right-radius: $card-border-radius;
		border-bottom-left-radius: $card-border-radius;
	}

	.card:last-of-type {
		border-top-left-radius: $card-border-radius;
		border-top-right-radius: $card-border-radius;
	}

		.accordion__header-link {

			&:not(.collapsed) {

				.accordion__header-link-icon {
					border-bottom-right-radius: 0;
				}
			}

			&.collapsed {

				.accordion__header-link-icon {
					border-bottom-right-radius: $card-border-radius;
				}
			}
		}
}
