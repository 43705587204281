/* Player General Stats */
.player-general-stats {

}
	.player-general-stats__item {

	}
		.player-general-stats__card-content {
			display: flex;
			align-items: center;

			@media (min-width: 992px) and (max-width: 1199px) {
				padding: calc($card-vertical-padding / 1.5) calc($card-horizontal-padding / 1.5);
			}
		}

			.player-general-stats__icon {
				margin-right: 20px;

				@media (min-width: 992px) and (max-width: 1199px) {
					margin-right: 10px;
				}
			}

			.player-general-stats__body {
				flex: 1;
				display: flex;
				align-items: center;
			}
				.player-general-stats__value {
					font-size: 36px;
					line-height: 1.2em;
					font-style: normal;
					margin-bottom: 0;
					margin-right: .45em;

					@media (min-width: 576px) and (max-width: 767px) {
						font-size: 26px;
					}

					@media (min-width: 992px) and (max-width: 1199px) {
						font-size: 24px;
					}
				}
				.player-general-stats__meta {
					flex: 1;
				}
					.player-general-stats__label {
						margin-bottom: 0;
						font-size: 12px;
						line-height: 1.2em;
					}
					.player-general-stats__sublabel {
						font-size: 9px;
						line-height: 1.2em;
					}
