
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
-webkit-touch-callout: none;
-webkit-user-select: none;
-ms-touch-action: none;
	touch-action: none;
-ms-user-select: none;
-moz-user-select: none;
	user-select: none;
-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.noUi-target {
	position: relative;
	direction: ltr;
}
.noUi-base {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 1; /* Fix 401 */
}
.noUi-connect {
	position: absolute;
	right: 0;
	top: 0;
	left: 0;
	bottom: 0;
}
.noUi-origin {
	position: absolute;
	height: 0;
	width: 0;
}
.noUi-handle {
	position: relative;
	z-index: 1;
}
.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
-webkit-transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
	transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
}
.noUi-state-drag * {
	cursor: inherit !important;
}

/* Painting and performance;
 * Browsers can paint handles in their own layer.
 */
.noUi-base,
.noUi-handle {
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
	height: 4px;
}
.noUi-horizontal .noUi-handle {
	width: 12px;
	height: 12px;
	left: -6px;
	top: -4px;
}
.noUi-vertical {
	width: 4px;
}
.noUi-vertical .noUi-handle {
	width: 12px;
	height: 12px;
	left: -4px;
	top: -6px;
}

/* Styling;
 */
.slider-range-wrapper {
	padding-top: $range-top-padding;
}
.noUi-target {
	background-color: $range-bg-color;
	border-radius: $range-border-radius;
}
.noUi-connect {
	background-color: $shop-price-filter-bar-color;
	transition: background 450ms;
}

/* Handles and cursors;
 */
.noUi-draggable {
	cursor: w-resize;
}
.noUi-vertical .noUi-draggable {
	cursor: n-resize;
}
.noUi-handle {
	border: 4px solid $shop-price-filter-handle-color;
	border-radius: $range-handle-border-radius;
	background-color: #fff;
	cursor: default;

	@if $template == 'football' {
		border: none;
		box-shadow: inset 0 0 0 4px $shop-price-filter-handle-color;
		position: relative;
		padding-top: 2px;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			bottom: -6px;
			width: 0;
			height: 0;
			border-top: 6px solid $shop-price-filter-handle-color;
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
		}
	}
}
.noUi-active {

}

/* Disabled state;
 */

[disabled] .noUi-connect {
	background: #B8B8B8;
}
[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
	cursor: not-allowed;
}

/* Labels */
.slider-range-label {
	@include font-accent(10px);
	color: $range-label-color;
	line-height: 1.2em;

	.slider-range + & {
		padding-top: 26px;
	}

	> * {
		color: $range-label-price-color;
	}
}
