/* Google Map
/* ------------------------- */

.gm-map {
	display: block;
	width: 100%;
	height: 240px;

	@media (min-width: 992px) {
		height: 350px;
	}
}

	// Sizes
	.gm-map--sm {
		height: 180px;

		@media (min-width: 992px) {
			height: 200px;
		}
	}

	.gm-map--lg {
		height: 300px;

		@media (min-width: 992px) {
			height: 460px;
		}
	}

	.gm-map--lg-2 {
		height: 280px;

		@media (min-width: 992px) {
			height: 360px;
		}
	}

	.gm-map--xl {
		height: 320px;

		@media (min-width: 992px) {
			height: 560px;
		}
	}
