/* Product Tabs (deprecated since 4.2.0) */

.product-tabs {

	.nav-product-tabs {
		border-bottom: 0;

		.nav-item {
			border-radius: 0;
		}

		.nav-link {
			position: relative;
			margin-right: 0;
			border-radius: 0;
			@include font-accent(11px);
			color: $body-font-color;
			line-height: 1.2em;
			padding: 28px 20px;
			transition: color .3s ease;
			border-top: none;
			margin-bottom: 0;
			border-bottom: 1px solid $card-border-color;

			@media (min-width: 992px) {
				font-size: 13px;
			}

			&::before {
				content: "";
				display: block;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: 0;
				background-color: $color-primary;
				transition: height 0.3s ease;
			}

			> small {
				display: block;
				font-size: 8px;
				@include fix_small_chars();
				line-height: 1.2em;
				color: $body-font-color;
			}

			&:hover {
				background-color: transparent;
				border-left: none;
				border-right: none;
				color: $headings-color;

				> small {
					color: $body-font-color;
				}
			}

			&.active,
			&.active:hover,
			&.active:focus {
				border: 1px solid $card-border-color;
				border-left: none;
				border-right: none;
				border-top: none;
				background-color: transparent;
				color: $headings-color;

				&::before {
					height: 4px;
				}
			}
		}



		@media (min-width: 768px) {

			.nav-link {
				border-radius: 0;
				border-right: none;
				border-top: none;
			}

			.nav-item:first-child .nav-link {
				border-left: none !important;
			}

			.nav-link.active,
			.nav-link.active:hover,
			.nav-link.active:focus {
				border-bottom-color: transparent;
				border-right: none;
				border-top: none;
			}

			.nav-item + .nav-item .nav-link {
				border-left: 1px solid $card-border-color !important;
			}
		}
	}

	// Clean (used outside the card)
	.nav-product-tabs--clean {
		border-block-end: 1px solid $card-border-color;

		.nav-item {
			margin-block-end: -1px;
		}

		.nav-item + .nav-item {

			.nav-link {
				border-inline: 0 !important;
			}
		}
	}

	// Centered
	.nav-product-tabs--center {
		justify-content: center;

		.nav-link {
			text-align: center;
			padding-inline: 1.5em;

			@media (min-width: 576px) {
				padding-inline: 2.5em;
			}

			@media (min-width: 768px) {
				padding-inline: 3em;
			}

			@media (min-width: 992px) {
				padding-inline: 5em;
				font-size: $font-size-sm;
			}

			@media (min-width: 1200px) {
				padding-inline: 7.5em;
				font-size: $font-size-sm;
			}
		}
	}
}


	// Tab Content Section
	.product-tabs__section {

		& + & {
			margin-top: 30px;
		}
	}

		.alc-product-tabs__inner {
			padding-block: 30px 15px;

			@media (min-width: 992px) {
				padding-block: 45px 25px;
			}

			@media (min-width: 1200px) {
				padding-block: 65px 40px;
			}
		}

	// Tab Header
	.product-tabs__header {
		padding-bottom: 2.5em;

		h2 {
			margin-bottom: 0;

			@if $template == 'football' {
				font-style: italic;
			}
		}

		.ratings {
			font-size: 11px;
			padding: 13px 0 0 0;

			.fa,
			.fab,
			.fas {
				margin-right: 3px;
			}
		}
		.ratings-result {
			display: inline-block;
			@include font-accent(11px);
			padding-left: 9px;
		}

		h2 + .ratings {

		}
	}

	.product-tabs__header--sm {

		h2 {
			font-size: 28px;
			display: flex;
			flex-wrap: wrap;
		}
			.product-tabs__ratings {
				flex-grow: 1;

				.highlight {

					@if $template == football {
						color: $color-4;
					} @else {
						color: $color-primary;
					}
				}
			}

			.product-tabs__reviews {
				flex-shrink: 1;
			}
	}

	#textarea-review {
		height: 162px;
	}


	// Product Header - New
	.alc-product-tabs__header {
		display: flex;
		justify-content: space-between;
		margin-block-end: 40px;

		@media (min-width: 992px) {
			margin-block-end: 65px;
		}
	}
		.alc-product-tabs__header-primary {

		}
			.alc-product-tabs__header-title {
				margin-bottom: .4em;
				line-height: 1em;
			}
			.alc-product-tabs__header-ratings-wrapper {
				display: flex;
				flex-wrap: wrap;
				gap: 10px;
				align-items: center;
			}
				.alc-product-tabs__header-ratings-title {
					color: $headings-color;
				}
				.alc-product-tabs__header-ratings-stars {
					font-size: 10px;
					line-height: 1em;
				}

		.alc-product-tabs__header-secondary {

		}


// Table Info
.alc-product-table-info {

	> tbody {

		> tr {

			> th {
				text-transform: none;
				font-weight: bold;
				color: $body-font-color;
			}

			> td {
				text-align: end;
			}

			> th,
			> td {

				@media (min-width: 992px) {
					padding: 16px $card-horizontal-padding;
				}
			}
		}
	}
}
