/* Team Info Banner */
.alc-team-info-banner {
	--column-gap: 30px;
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-template-rows: auto auto 1fr;
	column-gap: var(--column-gap);
	background-color: $card-bg;
	border: 1px solid $card-border-color;

	@media (min-width: 576px) {
		grid-template-rows: auto 1fr;
	}

	@media (max-width: 991px) {
		column-gap: 0;
	}
}
	// Header
	.alc-team-info-banner__header {
		grid-column: 5 / 10;
		grid-row: 1 / 2;
		padding: 45px 0 0 0;
		margin-bottom: 40px;
		z-index: 2;

		@media (min-width: 992px) {
			margin-inline-start: calc(var(--column-gap) * -1);
		}

		@media (max-width: 991px) {
			grid-column: 6 / -2;
			padding: 0;
			margin: 0;
			align-self: center;
		}

		// @media (max-width: 767px) {
		// 	grid-column: 8 / -1;
		// }
	}
		// Label
		.alc-team-info-banner__team-label {
			font-size: 10px;
			line-height: 1.2em;
			margin-bottom: .25em;
		}
		// Title
		.alc-team-info-banner__team-title {
			font-size: $font-size-h3;
			line-height: 1em;
			margin-bottom: 0;

			@media (min-width: 768px) {
				font-size: $font-size-h2;
			}

			@media (min-width: 992px) {
				font-size: $font-size-h2;
			}
		}

	// Logo
	.alc-team-info-banner__logo {
		grid-column: 1 / 5;
		grid-row: 1 / -1;
		justify-self: center;
		align-self: center;
		text-align: center;
		z-index: 2;
		padding-block: 40px 60px;

		@media (max-width: 991px) {
			grid-column: 1 / 6;
			grid-row: 1 / 2;
			padding-block: 20px;
		}

		img {
			max-width: 80%;

			@media (max-width: 991px) {
				max-width: 60%;
			}

			@media (min-width: 992px) {
				max-width: 100%;
			}
		}
	}

	// Meta Info
	.alc-team-info-banner__meta {
		grid-column: 5 / 10;
		grid-row: 2 / -1;
		z-index: 2;
		padding-bottom: 40px;

		@media (min-width: 992px) {
			margin-inline-start: calc(var(--column-gap) * -1);
		}

		@media (max-width: 991px) {
			grid-column: 1 / 10;
			grid-row: 2 / 3;
			padding-block-end: $card-vertical-padding;
			padding-bottom: 0;
			border-block-start: 1px solid $card-border-color;
		}

		@media (max-width: 575px) {
			grid-column: 1 / -1;
		}
	}

		.alc-team-info-banner__list-info {

			&.list-info {
				list-style-type: none;
				padding: 0;
				margin: 0;
				display: grid;
				grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
				column-gap: 30px;
				row-gap: 24px;

				@media (max-width: 991px) {
					padding-block: $card-vertical-padding;
					padding-inline: $card-horizontal-padding;
				}

				@media (max-width: 575px) {
					padding-block: calc($card-vertical-padding / 2);
					padding-inline: calc($card-vertical-padding / 2);
					column-gap: 10px;
				}
			}
				.list-info__item {

				}
					.list-info__label {
						font-size: 10px;
						line-height: 1.2em;
						margin-bottom: .5em;
					}
					.list-info__value {
						font-size: $font-size-sm;
						line-height: 1.2em;
						color: $headings-color;
						font-weight: bold;
					}
					.list-info__flag {
						margin-right: .5em;
					}
		}

	.alc-player-meta {

	}

	// Primary Info (Circular Bar)
	.alc-team-info-banner__primary-info {
		grid-column: 10 / -1;
		grid-row: 1 / -1;
		align-self: center;
		justify-self: start;
		padding-block-start: $card-vertical-padding * 2;

		@media (max-width: 991px) {
			grid-column: 9 / -1;
			grid-row: 2 / 3;
			justify-self: stretch;
			align-self: stretch;
			border-block-start: 1px solid $card-border-color;
			padding-block-start: 0;
			display: flex;

			.alc-circle-progress {
				margin: auto;
			}
		}

		@media (max-width: 767px) {
			grid-column: 9 / -1;
			grid-row: 2 / 3;
			padding-block-start: $card-vertical-padding;
			display: block;
		}

		@media (max-width: 575px) {
			grid-column: 1 / -1;
			grid-row: 3 / -1;
			align-self: center;
			justify-self: center;
			padding-block-start: calc($card-vertical-padding / 2);
			padding-block-end: $card-vertical-padding;
			border-block-start: none;
		}

		.alc-circle-progress--xl {

			@media (max-width: 991px) {
				--bar-size-multiplier: 1.3;
			}

			@media (max-width: 767px) {
				--bar-size-multiplier: 1.1;
			}

			@media (max-width: 575px) {
				--bar-size-multiplier: 1;
			}
		}
	}

	// Background
	.alc-team-info-banner__bg {
		grid-column: 1 / 5;
		grid-row: 1 / -1;
		background-image: url("../images/hockey/samples/team-single-bg.jpg");
		background-position: 0 50%;
		background-size: cover;
		clip-path: polygon(0 0, calc(100% - 90px) 0, calc(100% - 212px) 100%, 0% 100%);

		@media (max-width: 991px) {
			grid-column: 1 / 6;
			grid-row: 1 / 2;
			clip-path: polygon(0 0, 76% 0, 46% 100%, 0% 100%);
		}
	}
