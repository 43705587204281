// Buttons

@mixin button-variant($color, $color-hover, $background, $background-hover, $border, $border-hover) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:focus,
  &.focus {
    color: $color-hover;
    background-color: $background-hover;
        border-color: $border-hover;
  }
  &:hover {
    color: $color-hover;
    background-color: $background-hover;
        border-color: $border-hover;
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color-hover;
    background-color: $background-hover;
        border-color: $border-hover;

    &:hover,
    &:focus,
    &.focus {
      color: $color-hover;
      background-color: darken($background, 17%);
          border-color: $border-hover;
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] {
    background-color: $background;
    border-color: $border;

    & {
      &:hover,
      &:focus,
      &.focus {
        background-color: $background;
            border-color: $border;
      }
    }
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $color-hover;
    background-color: darken($background-hover, 10%);
    border-color: darken($border-hover, 10%);
  }

  .badge {
    color: $background;
    background-color: $color;
  }
}

// Button sizes
@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius, $text-transform) {
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  text-transform: $text-transform;
  line-height: $line-height;
  border-radius: $border-radius;
}
