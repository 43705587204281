/* Jumbotron
/* ------------------------- */

.jumbotron {
	padding: $jumbotron-padding calc($jumbotron-padding / 2);
	margin-bottom: $jumbotron-padding;
	background-color: $jumbotron-bg;
	background-position: 50% 0;
	background-repeat: no-repeat;
	background-size: cover;
	border: 1px solid $jumbotron-border-color;

	@media (min-width: 992px) {
		padding: ($jumbotron-padding * 2) $jumbotron-padding;
	}
}
	.jumbotron--card {
		border: 1px solid $card-border-color;
		border-radius: $card-border-radius;
	}

	// Jumbotron Style 1
	.jumbotron--style1 {

		@if $template == "esports" {
			background-image: url("../images/esports/samples/jumbotron-bg1.jpg");
		}

		padding: 0;
		position: relative;

		@media (min-width: 992px) {
			padding: 0;
		}

		@media (min-width: 768px) {
			// Overlay
			&::before {
				content: "";
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				background-image: $page-heading-bg-pattern-url;
				background-size: $page-heading-bg-pattern-size;
				background-position: 100% 0;
				background-repeat: $page-heading-bg-pattern-repeat;
				opacity: $page-heading-bg-pattern-opacity;
			}
		}

		.jumbotron__title {
			line-height: 1em;
			margin-bottom: 0;

			@media (max-width: 479px) {
				font-size: 24px;
			}

			@media (min-width: 992px) {
				font-size: 72px;
			}

			@media (min-width: 1200px) {
				font-size: 90px;
			}

			.highlight {
				color: $color-primary;
			}
		}
		.jumbotron__subtitle {
			margin-bottom: .25em;

			@media (max-width: 479px) {
				font-size: 9px;
			}

			@media (min-width: 1200px) {
				font-size: 14px;
			}
		}
		.jumbotron__desc {
			padding-top: .25em;
			margin-bottom: 0;

			@media (max-width: 479px) {
				font-size: 10px;
				line-height: 1.2em;
			}
		}

		.jumbotron__content {
			padding: $jumbotron-padding calc($jumbotron-padding / 2);

			@media (min-width: 992px) {
				padding: ($jumbotron-padding * 2) $jumbotron-padding;
			}
		}
	}
