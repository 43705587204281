/* Alerts
/* ------------------------- */

.alert {
	padding: $alert-padding;
	padding-left: 25px + $alert-icon-width;
	border-radius: $alert-border-radius;
	margin-bottom: 20px;
	border: 1px solid transparent;
	font-size: 13px;
	position: relative;
	color: $body-font-color;

	&::before {
		content:"";
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		border-radius: $alert-border-radius 0 0 $alert-border-radius;
		width: $alert-icon-width;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: 15px 15px;
	}

	strong {
		color: $alert-txt-accent-color;
		font-size: $alert-highlight-text-size;
		text-transform: $alert-highlight-text-transform;
		font-family: $font-family-accent;
	}
}


// Dismissible alerts
.alert-dismissible {
	padding-right: ($alert-padding + 20);

	// Adjust close link position
	.close {
		top: $alert-padding;
		right: $alert-padding;
		color: inherit;
		text-shadow: none;
		padding: 0;
		font-size: 21px;
		font-weight: bold;
		line-height: 1;
		opacity: .2;
	}
}

.alert-btn-right {
	position: relative;
	float: right;
	top: 0;
	right: 0;
}


// Alternate styles

.alert-success {
	@include alert-variant($alert-success-bg, $alert-success-border, $alert-success-icon-bg);

	&::before {
		background-size: 14px 12px;
		background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 12'%3E%3Cpath transform='translate(-189.438 -2446.25)' fill='#{$color-white}' d='M201.45,2446.24l2.121,2.13-9.192,9.19-2.122-2.12Zm-4.949,9.2-2.121,2.12-4.95-4.95,2.121-2.12Z'/%3E%3C/svg%3E"), "#", "%23");
	}
}

.alert-info {
	@include alert-variant($alert-info-bg, $alert-info-border, $alert-info-icon-bg);

	&::before {
		background-size: 5px 18px;
		background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5 18'%3E%3Cpath transform='translate(-194 -2257)' fill='#{$color-white}' d='M194.714,2257h3.572a0.716,0.716,0,0,1,.714.72l-0.714,11.16a0.716,0.716,0,0,1-.715.72h-2.142a0.716,0.716,0,0,1-.715-0.72L194,2257.72A0.716,0.716,0,0,1,194.714,2257Zm0.715,14.4h2.142a0.716,0.716,0,0,1,.715.72v2.16a0.716,0.716,0,0,1-.715.72h-2.142a0.716,0.716,0,0,1-.715-0.72v-2.16A0.716,0.716,0,0,1,195.429,2271.4Z'/%3E%3C/svg%3E"), "#", "%23");
	}
}

.alert-warning {
	@include alert-variant($alert-warning-bg, $alert-warning-border, $alert-warning-icon-bg);

	&::before {
		background-size: 5px 18px;
		background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5 18'%3E%3Cpath transform='translate(-194 -2257)' fill='#{$color-white}' d='M194.714,2257h3.572a0.716,0.716,0,0,1,.714.72l-0.714,11.16a0.716,0.716,0,0,1-.715.72h-2.142a0.716,0.716,0,0,1-.715-0.72L194,2257.72A0.716,0.716,0,0,1,194.714,2257Zm0.715,14.4h2.142a0.716,0.716,0,0,1,.715.72v2.16a0.716,0.716,0,0,1-.715.72h-2.142a0.716,0.716,0,0,1-.715-0.72v-2.16A0.716,0.716,0,0,1,195.429,2271.4Z'/%3E%3C/svg%3E"), "#", "%23");
	}
}

.alert-danger {
	@include alert-variant($alert-danger-bg, $alert-danger-border, $alert-danger-icon-bg);

	&::before {
		background-size: 11px 11px;
		background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 11'%3E%3Cpath transform='translate(-190.844 -2353.84)' fill='#{$color-white}' d='M190.843,2355.96l2.121-2.12,9.193,9.2-2.122,2.12Zm9.192-2.12,2.122,2.12-9.193,9.2-2.121-2.12Z'/%3E%3C/svg%3E"), "#", "%23");
	}
}
