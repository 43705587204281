/* Widget: Statistics Slider */
.widget-stats-slider {

}
	.alc-stats-slider {

	}
		.alc-stats-slider__header {

		}
		.alc-stats-slider__content {

		}

			.alc-stats-slider__stats-primary {
				padding: 30px 0;
			}


// Stats List
.alc-stats-list {
	display: flex;
	flex-wrap: wrap;
}
.alc-stats-list--3cols-sm {

	@media (max-width: 767px) {
		row-gap: 30px;

		.alc-stats-list__item {
			flex-basis: 33.3%;
			max-width: 33.3%;
		}
	}
}
	.alc-stats-list__item {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
	}

// Stats Block
.alc-stat-block {
	text-align: center;
	font-size: 10px;
	line-height: 1.2em;
}
	.alc-stat-block__header {

	}
		.alc-stat-block__title {
			font-size: $font-size-h3;
			line-height: 1.2em;
			margin-bottom: .2em;
			position: relative;
		}
			.alc-stat-block__title-diff {
				position: absolute;
				font-size: 10px;
				line-height: 1em;
				top: .5em;
				margin-left: 0.5em;
				vertical-align: super;
			}
			.alc-stat-block__title-diff--positive {
				color: $color-success;
			}
			.alc-stat-block__title-diff--negative {
				color: $color-danger;
			}

		.alc-stat-block__label {

		}

		.alc-stat-block__bar {
			margin-top: 10px;

			.circular__bar {
				width: 20px;
				height: 20px;
			}
				.circular__percents--chart {
					&::before {
						content: "";
						display: block;
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
						width: 8px;
						height: 8px;
						background-image:
							linear-gradient(to right, $headings-color, $headings-color 2px),
							linear-gradient(to right, $headings-color 4px, $headings-color 6px),
							linear-gradient(to right, $headings-color 6px, $headings-color 8px);
						background-repeat: no-repeat, no-repeat, no-repeat;
						background-size: 2px 6px, 2px 100%, 2px 50%;
						background-position: 0 100%, 3px 100%, 6px 100%;
					}
				}
		}


// Stats Grid
.alc-stats-grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
	gap: 1px;
	background-color: $card-border-color;
	border-top: 1px solid $card-border-color;
	font-size: 11px;
	line-height: 1.2em;
}
	.alc-stats-grid__item {
		background-color: $card-bg;
		display: flex;
		justify-content: space-between;
		padding: $card-vertical-padding - 7px $card-horizontal-padding;
	}
		.alc-stats-grid__label {
			font-weight: bold;
		}
		.alc-stats-grid__value {
			font-weight: bold;
			color: $headings-color;
		}
