/*
// #Blog
// --------------------------------------------------
*/

.content {

}

.sidebar {

}

.sidebar--extra-top {
	// z-index: 4;

	@media (min-width: 992px) {
		margin-top: -( $content-padding-vertical - $card-margin-vertical );
	}
}
