/* Post Meta */

// Simple Meta
.meta {
	list-style: none;
	padding: 0;
	margin: 0;
}
	.meta__item {
		font-family: $font-family-accent;
		font-size: 10px;
		text-transform: uppercase;
		line-height: 1.2em;
		display: inline-block;
		vertical-align: middle;

		& + & {
			margin-left: 8px;

			@media (min-width: 992px) {
				margin-left: 15px;
			}
		}

		&::before {
			@include icon-simple-line(13px);
			margin-right: 4px;
			position: relative;
			top: -1px;

			@media (min-width: 992px) {
				margin-right: 8px;
			}
		}

		a {
			color: $body-font-color;
			transition: color 0.2s ease;

			&:hover {
				color: $headings-color;

				@if $template == football {
					color: #fff;
				}
			}
		}
	}

	// Date
	.meta__item--date {

		@if $template == 'esports' {
			font-weight: bold;
			font-style: italic;
		}

		&::before {
			display: none;
		}
	}

	// Views
	.meta__item--views {

		&::before {
			content: "\e087";
		}
	}

	// Likes
	.meta__item--likes {

		&::before {
			display: none;
		}
		.meta-like {
			vertical-align: middle;
			margin-right: 6px;
			font-size: 12px;
		}
		.meta-like--active {
			color: #f1365e;
		}
	}

	// Comments
	.meta__item--comments {

		&::before {
			content: "\e07d";
			font-size: 12px;
		}
	}

	// Author
	.meta__item--author {

		img {
			border-radius: 50%;
			margin-right: 6px;
		}
	}


/* Side Meta Block */
.post__meta-block {

}
	// Side Meta Block
	.post__meta-block--side {
		padding: 24px;

		.post-author__name {
			font-size: 13px;
		}

		.social-links--btn {
			margin-left: 74px;
		}

		.post__meta {
			display: none;
		}

		@media (min-width: 992px) {
			padding: 0;
			float: left;
			width: 60px;
			margin: 43px 0 0 24px;

			& + .card__content {
				overflow: hidden;
				padding-left: 24px;
			}

			// Post Author
			.post-author {
				text-align: center;
				padding-bottom: 34px;
			}
				.post-author__avatar {
					float: none;
					margin: 0 auto 8px auto;
					width: 60px;
					height: 60px;
					border-radius: 50%;
				}
					.post-author__name {
						font-size: 10px;
						line-height: 1.2em;
						margin-bottom: 0.75em;
					}
					.post-author__slogan {
						font-size: 9px;
					}

			// Meta Info
			.post__meta {
				display: block;
			}
				.meta__item {
					display: block;
					text-align: center;
					margin: 0 0 21px 0;

					&::before {
						display: block;
						margin: 0 auto 6px auto;
					}
				}

					.meta__item--likes {

						.meta-like {
							display: block;
							margin: 0 auto 6px auto;
						}
					}

			// Sharing Links
			.social-links--btn {
				text-align: center;
				padding-bottom: 20px;
				margin-left: 0;

				.social-links__item {
					display: block;
					margin: 0 auto 12px auto;
				}
			}
		}
	}


	// Top Meta Block
	.post__meta-block--top {
		padding-bottom: 65px;

		@if $template == 'football' or $template == 'esports' {

			@media (min-width: 992px) {
				padding: 156px 0 215px 0;
			}
		}

		.post__category {
			margin-bottom: 22px;
		}
			.posts__cat-label {
				display: inline-block;
				float: none;
				font-size: 10px;
			}

		.page-heading__title {
			font-size: 24px;
			line-height: 1em;
			margin-bottom: 0.475em;

			@media (min-width: 992px) {
				font-size: 42px;
			}

			> a {
				color: #fff;
			}

			@if $template == 'football' or $template == 'esports' {
				font-style: italic;
			}
		}

		.post__meta {
			color: #fff;
			margin-bottom: 22px;
		}
			.meta__item {

				a {
					color: #fff;
				}
			}

			.posts__date {

				@if $template == 'football' or $template == 'esports' {
					color: #fff;
					font-size: 11px;
					margin-bottom: 30px;
				}
			}

		.post-author {
			text-align: center;
		}
			.post-author__avatar {
				display: block;
				width: 40px;
				height: 40px;
				margin: 0 auto 10px auto;

				@if $template == 'football' or $template == 'esports' {
					width: 24px;
					height: 24px;
					margin: 0 5px 0 0;
					border-radius: 50%;
					display: inline-block;
					vertical-align: middle;
				}
			}
		.post-author__info {

			@if $template == 'football' or $template == 'esports' {
				display: inline-block;
				vertical-align: middle;
			}
		}
			.post-author__name {
				font-size: 13px;
				color: #fff;

				@if $template == 'football' {
					font-size: 11px;
					margin-bottom: 0;
					font-style: normal;
				}

				@if $template == 'esports' {
					font-size: 12px;
					margin-bottom: 0;
				}
			}
			.meta__item--date {
				@if $template == 'esports' {
					font-size: 12px;
					margin-bottom: 0;
					font-weight: 700;
					font-style: italic;
				}
			}
			.post-author__slogan {
				font-size: 10px;
				font-weight: 400;
			}
	}

	.post__meta-block-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;

		@media (min-width: 992px) {
			height: 450px;
		}

		@media (min-width: 1200px) {
			height: 545px;
		}

		.post__meta-block--top {
			padding-bottom: 0;

			@if $template == 'football' or $template == 'esports' {

				@media (min-width: 992px) {
					padding: 0;
				}
			}
		}
	}


/* Side Meta Block - Wide */
.post__meta-block--side-wide {
	padding: 24px;

	.post__title {
		font-size: 18px;
		line-height: 1.0833em;

		@media (min-width: 992px) {
			font-size: 24px;
		}
	}

	.post-author__name {

	}

	.social-links--btn {

	}

	.post__meta {
		margin-bottom: 20px;
	}

	// Post Author
	.post-author {
		margin-bottom: 18px;
	}
		.post-author__avatar {
			width: 24px;
			height: 24px;
			display: inline-block;
			vertical-align: middle;
			border-radius: 50%;
			margin-right: 5px;
		}
			.post-author__name {
				font-size: 10px;
				line-height: 1.2em;
				color: $body-font-color;
				font-weight: 400;
				font-style: normal;
			}
			.post-author__slogan {

			}

	@media (min-width: 992px) {
		padding: 0;
		float: left;
		width: 190px;
		margin: 43px 0 0 24px;

		& + .card__content {
			overflow: hidden;
			padding-left: 60px;
		}

		// Meta Info
		.post__meta {

		}
			.meta__item {


				&::before {

				}
			}

				.meta__item--likes {

					.meta-like {

					}
				}

		// Sharing Links
		.social-links--btn-block {
			padding-right: 20px;
		}
	}
}


/* Post Meta - Row */
.posts__item,
.post--single {

	.post__meta--row {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		align-items: baseline;
		margin-bottom: 14px;

		.meta__item {
			font-size: $font-size-sm;
		}

		.meta__item + .meta__item {
			margin-left: .75em;
		}

		.meta__item--cats {
			margin-bottom: 0;
			padding-right: 0;
			display: flex;
		}

		.meta__item--cats + .meta__item--author {
			margin-left: 3px;
		}

		.meta__item--author {

			img {
				width: 29px;
				height: 23px;
				border-radius: 0;
				object-fit: cover;
				clip-path: polygon(8px 0, 100% 0%, calc(100% - 8px) 100%, 0% 100%);
			}
		}

		// post format
		.meta__item--post-format {
			width: 23px;
			height: 23px;
			line-height: 23px;
			background-color: #f21956;
			font-size: 9px;
			text-align: center;
			color: $color-white;
			transform: skew(-20deg);
			align-self: center;

			&:first-child {
				margin-left: 3px;
			}

			.fa,
			.fas,
			.fab {
				transform: skew(20deg);
			}
		}

		.meta__item--post-format + .meta__item--cats {
			margin-left: 0;
		}

		// Post Meta - small
		&.post__meta--sm {
			align-items: center;
			margin-bottom: 9px;

			&:last-child {
				margin-bottom: 0;
			}

			.meta__item--cats + .meta__item--author {
				margin-left: -3px;
			}

			.meta__item {
				font-size: 10px;
			}

			.meta__item:not(.meta__item--cats) {
				text-transform: none;
			}

				.posts__cat-label {
					padding-top: .65em;
					padding-bottom: .6em;
					clip-path: polygon(6px 0, 100% 0%, calc(100% - 6px) 100%, 0% 100%);
				}

			.meta__item--author {
				font-weight: bold;

				img {
					width: 23px;
					height: 18px;
					object-fit: cover;
					clip-path: polygon(6px 0, 100% 0%, calc(100% - 6px) 100%, 0% 100%);
				}
			}

			// post format
			.meta__item--post-format {
				width: 18px;
				height: 18px;
				font-size: 6px;
				line-height: 18px;
				text-align: center;

				.fa,
				.fas,
				.fab {

				}
			}

			.meta__item--post-format + .meta__item--cats {
				margin-left: 0;
			}
		}
	}
}


// Post Meta - skewed
.post-meta-skewed {
	padding: 0 !important;
	overflow: hidden;
	background-color: $card-subheader-bg;
	border-top: 1px solid $card-border-color;

	.post__meta {
		display: flex;
		margin: 0;
		flex-basis: 100%;
		text-align: center;

		.meta__item {
			flex: 1 1 100%;
			margin: 0;
			padding: 1.85em 0.5em;
			position: relative;
			transform: skew(-20deg);

			// unskew skewed icons
			&::before {
				display: inline-block;
				transform: skew(20deg);
			}

			&:not(.meta__item--social) {
				border-right: 1px solid $card-border-color;
			}

			&:last-child {
				border-right: none;
			}

			> a,
			.meta__item-holder {
				display: inline-flex;
				transform: skew(20deg);
			}
		}
			.meta__item-holder {

			}

		.meta__item--social {
			padding: 0;
			transform: none;
			display: flex;

			&:last-child {
				margin-right: -10px;
			}

			> a {
				transform: skew(-20deg);
				display: flex;
				width: 100%;
				align-items: center;
				padding: 1.85em 0.5em;
				justify-content: center;

				i {
					font-size: 13px;
					color: $color-gray;
				}

				&:hover {
					background-color: $card-bg;
					transition: background-color .2s ease;

					.meta__item--social-label {

						@media (min-width: 992px) {
							width: auto;
							padding: 0 .6em;
							transition: width .3s ease, padding .3s ease;
						}
					}
				}
			}

			// compensate negative margin for last skewed element
			&:last-child {

				> a {
					padding-right: 10px;
				}
			}

			&:not(:last-child) > a {
				border-right: 1px solid $card-border-color;
			}
		}

		// generate all social links
		@each $color, $value in $social-links {
			.meta__item--social-#{$color} {

				> a:hover {

					i {
						color: $value;
					}
				}
			}
		}

			.meta__item--social-label {
				display: inline-block;
				padding: 0;
				width: 0;
				overflow: hidden;
				white-space: nowrap;
				transition: none;
			}
	}
}


// Post Meta Lg
.post__meta--lg {

	.meta__item {
		font-size: $font-size-sm;

		.meta__item-holder,
		> a {
			font-weight: bold;
			color: $headings-color;

			> i {
				color: $body-font-color;
			}
		}
	}
}
