/* Labels
/* ------------------------- */

.label {
	font-weight: bold;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	padding: .5em 1.1em;
	font-size: 8px;
	@include fix_small_chars();
	border-radius: $label-border-radius;
	font-family: $font-family-accent;
	color: $label-color;
	text-transform: uppercase;
}

// Add hover effects, but only for links
a.label {
	&:hover,
	&:focus {
		color: $label-link-hover-color;
	}
}

// Colors
// Contextual variations (linked labels get darker on :hover)

.label-default {
	@include label-variant($label-default-bg);
}

.label-primary {
	@include label-variant($label-primary-bg);
}

.label-success {
	@include label-variant($label-success-bg);
}

.label-info {
	@include label-variant($label-info-bg);
}

.label-warning {
	@include label-variant($label-warning-bg);
}

.label-danger {
	@include label-variant($label-danger-bg);
}

.label-win,
.label-loss {
	display: inline-block;
	width: 24px;
	height: 24px;
	line-height: 24px;
	padding: 0;
	text-align: center;
	font-size: 10px;
	color: $color-dark;
	border-radius: 4px;
}

.label-win {
	@include label-variant($label-success-bg);
}


.label-loss {
	@include label-variant($label-danger-bg);
}

// Basic
.label--basic {
	display: inline-block;
	width: 12px;
	height: 12px;
	line-height: 12px;
	border-radius: 0;
	padding: 0;
	font-size: 8px;
	font-weight: bold;
	color: $color-white;
}
.label-loss--basic {
	background-color: $color-gray;
}
.label-win--basic {
	@include label-variant($label-warning-bg);
}
