/* Widget: Games History */
.widget-games-history {

}
	.games-history-chart {

	}

	.chart-legend {

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			@include font-accent(9px);
			color: $headings-color;

			@if $template == 'hockey' {
				font-size: 10px;
				text-transform: none;
			}

			> li {
				display: inline-block;
				padding-left: 20px;

				&:first-child {
					padding-left: 0;
				}

				> span {
					display: inline-block;
					width: 8px;
					height: 8px;
					border-radius: 2px;
					margin-right: 10px;

					@if $template == 'hockey' {
						border-radius: 0;
					} @else {
						border-radius: 2px;
					}
				}
			}
		}
	}

	.chart-legend--center {
		padding-top: 42px;

		ul {
			display: flex;
			font-size: 12px;
			text-transform: none;

			> li {
				flex-basis: 0;
				flex-grow: 1;
				max-width: 100%;
				padding: 0;
				text-align: center;

				@if $template == 'soccer' {
					text-transform: uppercase;
					font-size: 10px;
					color: $body-font-color;
				}

				> span {
					display: block;
					margin: 0 auto 10px auto;
				}
			}
		}
	}
